import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import { AiFillEdit } from "react-icons/ai"
// context
import TranslationProvider from 'context/TranslationProvider'
// styles
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const TcResellersTable = ({ resellers }) => {

  const { t } = useContext(TranslationProvider)

  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>{t('commercial_network.name')}</Th>
            <Th>{t('commercial_network.code')}</Th>
            <Th>Email</Th>
            <Th>{t('user.phone')}</Th>
            <Th>IRPF</Th>
            <Th>IVA</Th>
            <Th>Intranet</Th>
          </Tr>
        </Thead>
        <Tbody>
          {resellers.map((agent) => (
            <Tr key={agent.codigo}>
              <Td> {agent.nombre} </Td>
              <Td> {agent.codigo} </Td>
              <Td> {(agent.user !== null) ? agent.user.email : ""} </Td>
              <Td> {agent.telefono} </Td>
              <Td type="center"> {(parseFloat(agent.irpf) * 100) + ' %'} </Td>
              <Td type="center"> {(parseFloat(agent.iva) * 100) + ' %'} </Td>
              <Td type="center"> {(agent.user !== null) ? "Sí" : "No"} </Td>
              <Td>
                <Link to={`/teleconnect_resellers/edit/${agent.id}`}>
                  <AiFillEdit />
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default TcResellersTable