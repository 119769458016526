import {URL} from '../services/urls';

export default function getBanks(accesToken) {
  const apiURL = URL + `/react_bank_accounts`

  return fetch(apiURL, {
    headers: {
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => res.json())
    .then(response => {
      const tempArray = [];
      if (response) {
        if (response.length) {
          response.forEach((element) => {
            tempArray.push({
              label: `${element.name}`,
              value: element.id,
            });
          });
        } else {
          tempArray.push({
            label: `${response.name}`,
            value: response.id,
          });
        }
      }
      return tempArray
    })
}