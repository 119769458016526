import React from 'react'
import Select from "react-select"

const PerPageFilter = ({ perPage, setPerPage, setCurrrentPage }) => {

  const handlePerPageChange = (e) => {
    setCurrrentPage(1)
    setPerPage(e.value)
  }

  return (
    <Select
      options={[
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: 500, label: 500 },
      ]}
      onChange={(e) => handlePerPageChange(e)}
      value={{ value: perPage, label: perPage }}
    />

  );
};

export default PerPageFilter