import useGetMovileurosBanks from 'hooks/useGetMovileurosBanks'
import { Button, Container, Row, Col } from 'react-bootstrap'
import checkBankBalanceService from "services/checkBankBalanceService"

const ShowBankCheckBar = ({ bankSelected, auth, balance, setBankSelected }) => {

  const { banksWithBalanceCheck } = useGetMovileurosBanks(auth.accesToken)

  let checkbalance = null
  checkbalance = banksWithBalanceCheck.filter(element => element.id === bankSelected.value)

  const checkBankBalance = async () => {
    checkBankBalanceService(auth.accesToken, balance, bankSelected)
      .then(response => {
        setBankSelected(0)
        if (response.errorCode !== 0) {
          //setErrMsg(response.message.message_en)
        }
      })
  }

  return (
    <Container >
      {
        checkbalance && checkbalance[0] !== undefined && checkbalance[0].last_check !== null &&
        <Row className="align-items-end justify-content-end">
          <Col md="auto">

            <h5>
              Banco chequeado a las: {(new Date(checkbalance[0].last_check.created_at)).toLocaleString('es-Es')}
              &nbsp; por {checkbalance[0].last_check.user.name}
            </h5>
          </Col>
          {(auth.roles.includes("root") /** Para que Amador pueda chequear su banco y los roots todos */
            || (!auth.roles.includes("root") && [4, 9].includes(bankSelected.value))
          ) &&
            <Col md="auto">
              <Button
                type="submit"
                variant="primary"
                onClick={checkBankBalance}
              >
                Check Saldo
              </Button>
            </Col>
          }
        </Row>
      }
    </Container>
  )
}

export default ShowBankCheckBar