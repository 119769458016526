import { useEffect, useState } from 'react'
import getTcCompanies from 'services/getTcCompanies'

export default function useGetTcCompanies(accesToken) {

  const [companies, setCompanies] = useState([])

  useEffect(() => {
    getTcCompanies(accesToken).then(companies => setCompanies(companies))
  }, [accesToken])

  return {
    companies
  }
} 