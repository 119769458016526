import React, { useEffect, useState, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import Chart from 'components/movileuros/sales/Chart'
import getMovileurosSales from 'services/getMovileurosSales'
import TranslationProvider from 'context/TranslationProvider'
import AuthProvider from 'context/AuthProvider'

const MovileurosSales = () => {

  const { t } = useContext(TranslationProvider)
  const { i18n } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)

  const [movileurosSales, setMovileurosSales] = useState([])

  useEffect(() => {
    getMovileurosSales(auth.accesToken).then(movileurosSales => setMovileurosSales(movileurosSales))
  }, [auth.accesToken])

  return (
    <Container>
      <h2 style={{ padding: 20, textAlign: "center" }} > Ventas de Movileuros</h2>
      <Tabs
        defaultActiveKey="daily"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="daily" title={t('daily')}>
          <Chart
            data={movileurosSales.dataByDay}
            xlabel='date'
            t={t}
            i18n={i18n}
            type={'daily'}
          />
        </Tab>
        <Tab eventKey="weekly" title={t('weekly')}>
          <Chart
            data={movileurosSales.dataByWeek}
            xlabel='week'
            t={t}
            i18n={i18n}
            type={'weekly'}
          />
        </Tab>
        <Tab eventKey="monthly" title={t('monthly')}>
          <Chart
            data={movileurosSales.dataByMonth}
            xlabel='month'
            t={t}
            i18n={i18n}
            type={'monthly'}
          />
        </Tab>
      </Tabs>
    </Container>
  )
}

export default MovileurosSales