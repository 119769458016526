import React from 'react';
import Button from 'react-bootstrap/Button';

const Pagination = ({ lastPage, currrentPage, paginate }) => {
  const pageNumbers = [];
  let previousPage = 1;
  let nextPage = 1;
  let buttons = 5;
  const firstPage = 1;

  previousPage = (currrentPage > 1) ? currrentPage - 1 : 1;
  nextPage = (currrentPage < lastPage) ? currrentPage + 1 : lastPage;

  //if (currrentPage > 1) previousPage = currrentPage -1 ;
  /* for (let i = 1; i <= Math.ceil(lastPage / perPage); i++) {
    pageNumbers.push(i);
  } */

  if (lastPage < 5) buttons = lastPage;
  for (let i = 1; i <= buttons; i++) {
    if (lastPage <= 5) pageNumbers.push(i);
    else {
      if (currrentPage + buttons <= lastPage ) pageNumbers.push(i + currrentPage - 1);
      else {
        if (currrentPage - buttons > lastPage) pageNumbers.push(i + currrentPage - buttons)
        else pageNumbers.push(i + currrentPage - buttons  + Math.abs(currrentPage - lastPage))
      };
    }
  }

  //console.log(currrentPage)
  return (
    <nav>
      <ul className='pagination'>
        <Button onClick={() => paginate(firstPage)} className='page-link'>
          {"<<"}
        </Button>
        <Button onClick={() => paginate(previousPage)} className='page-link'>
          {"<"}
        </Button>
        {pageNumbers.map(number => (
          <li key={number} className="page-item">
            <Button
              onClick={() => paginate(number)}
              className={(number === currrentPage) ? 'primary' : 'page-link'}
            >
              {number}
            </Button>
          </li>
        ))}
        <Button onClick={() => paginate(nextPage)} className='page-link'>
          {">"}
        </Button>
        <Button onClick={() => paginate(lastPage)} className='page-link'>
          {">>"}
        </Button>
      </ul>
    </nav>
  );
};

export default Pagination;