import React, { useContext } from "react"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Select from "react-select"
//context
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"
// hooks
import useGetTcCompanies from 'hooks/useGetTcCompanies'
import useGetTcManagers from 'hooks/useGetTcManagers'
import useGetTcSubmanagers from 'hooks/useGetTcSubmanagers'
import useGetTcResellers from 'hooks/useGetTcResellers'
// components
import PerPageFilter from './PerPageFilter'
import ActiveFilter from './ActiveFilter'
import SinceUntilDatePicker from './SinceUntilDatePicker'

const TcRenderSearchBar = ({ searchParameters, setSearchParameters, setCurrrentPage,
  handleCompanyChange, handleManagerChange, handleSubmanagerChange, handleResellerChange, clearPage,
  lookForText, showResellers, showPaginator, showActiveFilter, showSinceUntilDatePicker,
  startDate, changeStartDate, endDate, changeEndDate }) => {

  const auth = useContext(AuthProvider)
  const { t } = useContext(TranslationProvider)
  const { companies } = useGetTcCompanies(auth.accesToken)
  const { managers } = useGetTcManagers(auth.accesToken)
  const { submanagers } = useGetTcSubmanagers(auth.accesToken, searchParameters.manager)
  const { resellers } = useGetTcResellers(auth.accesToken, searchParameters.company, searchParameters.submanager, searchParameters.manager)


  return (
    <div style={{ margin: "10px 0px 30px 0px" }}>
      <Container>
        <Row className="justify-content-md-center">
          {showSinceUntilDatePicker &&
            <Col md="auto">
              <SinceUntilDatePicker
                setCurrrentPage={setCurrrentPage}
                startDate={startDate}
                changeStartDate={changeStartDate}
                endDate={endDate}
                changeEndDate={changeEndDate}
              />
            </Col>
          }
          <Col md="auto">
            <Select
              options={companies}
              onChange={(e) => handleCompanyChange(e)}
              placeholder={t("graphs.company_to_search")}
              value={searchParameters.company}
            />
          </Col>
          {auth.roles.includes("root") && (
            <Col md="auto">
              <Select
                options={managers}
                onChange={(e) => handleManagerChange(e)}
                placeholder={t("graphs.manager_to_search")}
                value={searchParameters.manager}
              />
            </Col>)
          }
          {!auth.roles.includes("Reseller") && !auth.roles.includes("Submanager") && (
            <Col md="auto">
              <Select
                options={submanagers}
                onChange={(e) => handleSubmanagerChange(e)}
                placeholder={t("graphs.submanager_to_search")}
                value={searchParameters.submanager}
              />
            </Col>)
          }
          {showResellers && !auth.roles.includes("Reseller") && (
            <Col md="auto">
              <Select
                options={resellers}
                onChange={(e) => handleResellerChange(e)}
                placeholder={t("graphs.reseller_to_search")}
                value={searchParameters.reseller}
              />
            </Col>)
          }
          {lookForText &&
            <Col md="auto">
              <Form.Control
                onChange={(e) => setSearchParameters({ ...searchParameters, text: e.target.value })}
                placeholder={t("commercial_network.search_in_any_field")}
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchParameters.text}
              />
            </Col>
          }
          {showPaginator &&
            <Col md="auto">
              <PerPageFilter
                setCurrrentPage={setCurrrentPage}
                searchParameters={searchParameters}
                setSearchParameters={setSearchParameters}
              />
            </Col>
          }
          {showActiveFilter &&
            <Col md="auto">
              <ActiveFilter
                searchParameters={searchParameters}
                setSearchParameters={setSearchParameters}
                setCurrrentPage={setCurrrentPage}
              />
            </Col>
          }
          <Col md="auto">
            <Button type="submit" variant="primary" onClick={clearPage}>
              {t("clean")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TcRenderSearchBar
