import React from "react"
import { Routes, Route } from "react-router-dom"

// pages
import ChangePassword from "pages/ChangePassword"
import Home from "pages/Home"
import InvoicesPage from "pages/Invoices"
import MovileurosSales from "pages/MovileurosSales"
import RootPage from "pages/Root"
import Settings from "pages/Settings"
import ShowAgents from "pages/MovileurosAgents"
import ShowBankEntries from "pages/MovileurosBanks"
import TestPage from "pages/TestPage"
import TeleconnectAgents from "pages/TeleconnectAgents"
import TeleconnectCommissions from "pages/TeleconnectCommissions"
import TeleconnectRechargues from "pages/TeleconnectRechargues"
import TeleconnectCredits from "pages/TeleconnectCredits"
import TeleconnectResellers from "pages/TeleconnectResellers"
import TeleconnectSales from "pages/TeleconnectSales"
//components
import CreateBankEntry from "components/banks/CreateBankEntry"
import CreateCreditEntry from "components/teleconnect/credits/CreateCreditEntry"
import EditBankEntry from "components/banks/EditBankEntry"
import EditAgent from "components/commercial_network/EditAgent"
import ShowAgent from "components/commercial_network/ShowAgent"
import ShowTeleconnectAgent from "components/teleconnect/agents/ShowAgent"
import EditReseller from "components/teleconnect/resellers/EditReseller"


const RenderRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/change_password" element={<ChangePassword />} />
      <Route
        path="/movileuros_sales_page"
        element={<MovileurosSales />}
      />
      <Route
        path="/teleconnect_sales_page"
        element={<TeleconnectSales />}
      />
      <Route
        path="/teleconnect_agents_page"
        element={<TeleconnectAgents />}
      />
      <Route
        path="/teleconnect_resellers_page"
        element={<TeleconnectResellers />}
      />
      <Route
        path="/teleconnect_rechargues_page"
        element={<TeleconnectRechargues />}
      />
      <Route
        path="/teleconnect_credits_page"
        element={<TeleconnectCredits />}
      />
      <Route
        path="/teleconnect_commissions_page"
        element={<TeleconnectCommissions />}
      />
      <Route
        path="/teleconnect_agents_page/show/:id"
        element={<ShowTeleconnectAgent />}
      />
      <Route path="/invoices_page" element={<InvoicesPage />} />
      <Route path="/root_page" element={<RootPage />} />
      <Route path="/test_page" element={<TestPage />} />
      <Route path="/commercial_network/agents" element={<ShowAgents />} />
      <Route
        path="/commercial_network/agents/edit/:id"
        element={<EditAgent />}
      />
      <Route
        path="/commercial_network/agents/show/:id"
        element={<ShowAgent />}
      />
      <Route path="/bank_entries" element={<ShowBankEntries />} />
      <Route path="/bank_entries/create" element={<CreateBankEntry />} />
      <Route path="/create_credit_entry" element={<CreateCreditEntry />} />
      <Route path="/bank_entries/edit/:id" element={<EditBankEntry />} />
      <Route
        path="/teleconnect_resellers/edit/:id"
        element={<EditReseller />}
      />
    </Routes>
  )
}

export default RenderRoutes
