import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import { AiFillEdit, AiFillEye } from "react-icons/ai"
import TranslationProvider from 'context/TranslationProvider'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const AgentsTable = (props) => {

  const { t } = useContext(TranslationProvider)

  return (
    <div>
      <Table striped responsive>
        <Thead className='bg-primary text-white'>
          <Tr type="center">
            <Th>{t('commercial_network.commercial')}</Th>
            <Th>{t('commercial_network.code')}</Th>
            <Th>{t('commercial_network.name')}</Th>
            <Th>{t('commercial_network.address')}</Th>
            <Th>{t('commercial_network.city')}</Th>
            <Th>{t('commercial_network.balance')}</Th>
            <Th>{t('commercial_network.credit')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.agents.map((agent) => (
            <Tr key={agent.id}>
              <Td> {agent.seller[0].user.name} </Td>
              <Td> {agent.income_code} </Td>
              <Td> {agent.registered_business_name.substring(0, 30)} </Td>
              <Td> {agent.active_location_model.address1.substring(0, 30)} </Td>
              <Td> {agent.active_location_model.city} </Td>
              <Td type="number">
                {(agent.balance !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(agent.balance) : 0}
              </Td>
              <Td type="number">
                {(agent.credit !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(agent.credit) : 0}
              </Td>
              <Td>
                <Link to={`/commercial_network/agents/edit/${agent.id}`}>
                  <AiFillEdit />
                </Link>
              </Td>
              <Td>
                <Link to={`/commercial_network/agents/show/${agent.id}`}>
                  <AiFillEye
                    style={{ "marginLeft": "-20px", "color": "#007bff" }}
                  />
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default AgentsTable