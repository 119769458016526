import api from 'services/axios'
import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// context
import AuthProvider from 'context/AuthProvider'

import EditResellerForm from "./EditResellerForm"
import { editReseller } from 'services/editReseller'

const EditReseller = () => {

  const auth = useContext(AuthProvider)
  const navigate = useNavigate()
  const { id } = useParams()
  const [formParameters, setFormParameters] = useState({
    codigo: '',
    nombre: '',
    email: '',
    password: '',
    telefono: '',
    irpf: 0,
    iva: 0,
    isNeeded2FA: false,
    hasAnIntranetUser: false,
  })
  const [errMsg, setErrMsg] = useState('')
  const [show, setShow] = useState(false)
  const [modalText, setModaltext] = useState("")

  const update = async () => {
    editReseller(parseFloat(formParameters.irpf), parseFloat(formParameters.iva), formParameters.nombre,
      formParameters.email, formParameters.password, formParameters.telefono, formParameters.isNeeded2FA, id, auth.accesToken, setErrMsg)
      .then(response => {
        if (!response.data.errorCode) navigate('/teleconnect_resellers_page')
        else {
          setModaltext(response.data.message.message_es)
          setShow(true)
        }
      })
  }

  /* eslint-disable */
  useEffect(() => {
    const getResellerById = async () => {
      const response = await api.get(`/teleconnect_resellers/${id}`)
      setFormParameters({
        ...formParameters,
        codigo: response.data.codigo,
        nombre: response.data.nombre,
        email: (response.data.user === null) ? "" : response.data.user.email,
        telefono: response.data.telefono,
        irpf: parseFloat(response.data.irpf).toFixed(2),
        iva: parseFloat(response.data.iva).toFixed(2),
        isNeeded2FA: (response.data.user?.isNeeded2FA === 1) ? true : false,
        hasAnIntranetUser: (response.data.user !== null) ? true : false,
      })
    }
    getResellerById()
  }, [])

  const goBack = async (e) => {
    e.preventDefault()
    navigate('/teleconnect_resellers_page')
  }

  const handleClose = () => setShow(false)

  return (
    <EditResellerForm
      formParameters={formParameters}
      setFormParameters={setFormParameters}
      update={update}
      modalText={modalText}
      setModaltext={setModaltext}
      handleClose={handleClose}
      goBack={goBack}
      errMsg={errMsg}
      show={show}
    />
  )
}
export default EditReseller