import styled from '@emotion/styled'

export const Form = styled.form`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
`
export const FormInput = styled.input`
  border-radius: 5px;
  margin: 10px;
`
export const FormButton = styled.button`
  border-radius: 5px;
  border: none;
  padding: 10px;
  margin-top: 15px;
  margin-right: 10px;
  background-color: #61DAFB;
`
export const FormButton2 = styled.button`
  border-radius: 5px;
  border: none;
  padding: 6px 12px;
  background-color: #0275d8;
  font-size: calc(7px + 1vmin);
  margin-left: 20px;
  margin-top: 15px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
`
export const FormLabel = styled.p`
  font-size: calc(8px + 1vmin);
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
`
export const FormError = styled.p`
  color: red;
  margin-top: 10px;
`
export const TextMuted = styled.p`
  color: #6c757d;
  margin-top: 30px;
`
export const FormHead = styled.h3`
  padding: 20px;
  text-align: center;
`

/* export const FormLabel = css({
  textAlign: "center",
  marginTop: "15px",
  marginBottom: "10px",
}) */
