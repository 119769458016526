import React, { Component } from "react"
import AsyncSelect from "react-select/async"

class ReactAsyncSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null,
    }
  }
  componentDidMount() {
    this.setState({
      selectedOption: this.props.initialOption,
    })
  }

  onSearchChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({
        selectedOption,
      })
      this.props.handleChange(selectedOption)
    }
  }

  render() {
    return (
      <div>
        <div>
          <AsyncSelect
            value={(this.state.selectedOption !== 0) ? this.state.selectedOption : {
              label: this.props.initialOption.label,
              value: this.props.initialOption.value
            }}
            loadOptions={this.props.fetchData}
            onChange={(e) => {
              this.onSearchChange(e)
            }}
            defaultOptions={true}
          />
        </div>
      </div>
    )
  }
}

export default ReactAsyncSelect
