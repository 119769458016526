import Select from "react-select"
import { useNavigate } from 'react-router-dom'
import React, { useContext } from 'react'

import { Button, Container, Row, Col, Form } from 'react-bootstrap'
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from 'context/TranslationProvider'
import "react-datepicker/dist/react-datepicker.css"
import PerPageFilter from 'components/controls/PerPageFilter'
import useGetMovileurosBanks from 'hooks/useGetMovileurosBanks'
import { banksInitialStartDate, banksInitialEndDate } from "config/sinceUntilDatePicker"
import SinceUntilDatePicker from 'components/controls/SinceUntilDatePicker'
import { AiOutlineReload } from "react-icons/ai";
// React-Toastify
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const BanksSearchBar = ({ textToFind, setTextToFind, setCurrrentPage, bankSelected, setBankSelected, startDate, changeStartDate,
  endDate, changeEndDate, perPage, setPerPage, setSortField, setSortOrder, originSelected, setOriginSelected, setRefresPage }) => {

  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const { banks } = useGetMovileurosBanks(auth.accesToken)

  const clearPage = () => {
    setCurrrentPage(1)
    setTextToFind("")
    setBankSelected(0)
    setOriginSelected(0)
    setSortField(null)
    setSortOrder(null)
    changeStartDate({ startDate: banksInitialStartDate })
    changeEndDate({ endDate: banksInitialEndDate })
  }

  const handleBankChange = (e) => {
    setCurrrentPage(1)
    setBankSelected({
      label: e.label,
      value: e.value
    })
  }

  const handleOriginChange = (e) => {
    setCurrrentPage(1)
    setOriginSelected({
      label: e.label,
      value: e.value
    })
  }

  const handleChangeInTextToFind = (e) => {
    setCurrrentPage(1)
    setTextToFind(e.target.value)
  }

  const options = [
    { value: 3, label: 'Cabinas' },
    { value: 1, label: 'Recargas' },
    { value: 2, label: 'Teleconnect sin IVA' },
    { value: 4, label: 'Teleconnect con IVA' },
    { value: 5, label: 'Teleconnect Prosper' },
  ]


  const notify = () => {
    toast("Refreshing page!");
  };

  return (
    <div style={{ marginBottom: "-10px" }}>
      <Container>
        <Row className="justify-content-md-center mb-3">
          <Col md="auto">
            <SinceUntilDatePicker
              setCurrrentPage={setCurrrentPage}
              startDate={startDate}
              changeStartDate={changeStartDate}
              endDate={endDate}
              changeEndDate={changeEndDate}
            />
          </Col>
          <Col md="auto">
            <Select
              options={options}
              onChange={(e) => handleOriginChange(e)}
              placeholder="Origen"
              value={originSelected}
            />
          </Col>
          <Col md="auto">
            <Select
              options={banks}
              onChange={(e) => handleBankChange(e)}
              placeholder={t('banks.bank_to_search')}
              value={bankSelected}
            />
          </Col>
          <Col md="auto">
            <Form.Control
              onChange={(e) => handleChangeInTextToFind(e)}
              placeholder={t("commercial_network.search_in_any_field")}
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={textToFind}
            />
          </Col>

          <Col md="auto">
            <PerPageFilter
              setCurrrentPage={setCurrrentPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Col>

          <Col md="auto">
            <Button
              type="submit"
              variant="primary"
              onClick={clearPage}
            >
              {t('clean')}
            </Button>
          </Col>

          <Col md="auto">
            <Button
              variant="primary"
              onClick={() => { navigate('/bank_entries/create') }}>
              {t('banks.create_bank_entry')}
            </Button>
          </Col>

          <Col md="auto">
            <AiOutlineReload
              style={{ color: "#007bff", marginTop: 7, cursor: "pointer" }}
              size={25}
              onClick={() => {
              setRefresPage(true)
              notify()
            }}
            />
          </Col>

        </Row>
      </Container>
      <ToastContainer autoClose={200} />

    </div>
  )
}

export default BanksSearchBar