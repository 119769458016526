import { URL } from './urls';

export default function getBankEntries(accesToken, currrentPage, perPage, bank_id, startDate, endDate, textToFind, sortField, sortOrder, origin_id ) {

  let bankSelectedForApi = (bank_id.value === undefined) ? 0 : bank_id.value
  let originSelectedForApi = (origin_id.value === undefined) ? 0 : origin_id.value

  const apiURL = URL + `/bank_entries_react?page=${currrentPage}&per_page=${perPage}&sortField=${sortField}&sortOrder=${sortOrder}&bank_id=${bankSelectedForApi}&textToFind=${textToFind}&startDate=${startDate.toISOString().slice(0, 10)}&endDate=${endDate.toISOString().slice(0, 10)}&origin_id=${originSelectedForApi}`

  return fetch(apiURL, {
    headers: {
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response
    })
}