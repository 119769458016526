let startDate = new Date()
startDate.setDate(startDate.getDate() - 2)

let bankEndDate = new Date()
bankEndDate.setDate(bankEndDate.getDate() + 5)

export const rechargesInitialStartDate = startDate
export const rechargesInitialEndDate = new Date()

export const banksInitialStartDate = startDate
export const banksInitialEndDate = bankEndDate
