import { useEffect, useState } from 'react'
import getBanks from 'services/getBanks'
import getBanksbanksWithBalanceCheck from 'services/getBanksbanksWithBalanceCheck'

export default function useGetMovileurosBanks(accesToken) {

  const [banks, setBanks] = useState([])
  const [banksWithBalanceCheck, setBanksWithBalanceCheck] = useState([])

  useEffect(() => {
    getBanks(accesToken).then(banks => setBanks(banks))
  }, [accesToken])

  useEffect(() => {
    getBanksbanksWithBalanceCheck(accesToken).then(banks => setBanksWithBalanceCheck(banks))
  }, [accesToken])

  return {
    banks,
    banksWithBalanceCheck
  }
} 