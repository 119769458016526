import React from 'react'

const RootPage = () => {

  return (
    <section className="App-content">
      Esta página sólo la vemos los users roots
    </section>
  )
}

export default RootPage