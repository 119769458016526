import React from "react"
import TcRenderCreditSearchBar from './TcRenderCreditSearchBar'
import { rechargesInitialStartDate, rechargesInitialEndDate } from "config/sinceUntilDatePicker"

const TcCreditSearchBar = ({ searchParameters, setSearchParameters, setCurrrentPage, lookForText = true, 
  showPaginator = true, startDate = null, changeStartDate = null, endDate = null, changeEndDate = null,
  showSinceUntilDatePicker = false }) => {

  const clearPage = () => {
    setSearchParameters({
      ...searchParameters,
      company: 0,
      manager: 0,
      submanager: 0,
      reseller: 0,
      text: ""
    })
    if (showPaginator) setCurrrentPage(1)
    if (showSinceUntilDatePicker) changeStartDate({ startDate: rechargesInitialStartDate })
    if (showSinceUntilDatePicker) changeEndDate({ endDate: rechargesInitialEndDate })
  }

  return (
    <TcRenderCreditSearchBar
      searchParameters={searchParameters}
      setSearchParameters={setSearchParameters}
      setCurrrentPage={setCurrrentPage}
      clearPage={clearPage}
      lookForText={lookForText}
      showPaginator={showPaginator}
      startDate={startDate}
      changeStartDate={changeStartDate}
      endDate={endDate}
      changeEndDate={changeEndDate}
      showSinceUntilDatePicker={showSinceUntilDatePicker}
    />
  )
}

export default TcCreditSearchBar
