import { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"

//components
import AgentsSearchBar from 'components/commercial_network/AgentsSearchBar'
import AgentsTable from 'components/commercial_network/AgentsTable'
import Pagination from 'components/controls/Pagination'
//context - config
import AuthProvider from 'context/AuthProvider'
// services
import getMovileurosAgentsTable from 'services/getMovileurosAgentsTable'

const ShowAgents = () => {

  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const [agents, setAgents] = useState([])
  const [agentToFind, setAgentToFind] = useState('')
  const [textToFind, setTextToFind] = useState('')

  const [currrentPage, setCurrrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [perPage, setPerPage] = useState(8)

  /* eslint-disable */
  useEffect(() => {
    getMovileurosAgentsTable(auth.accesToken, currrentPage, perPage, agentToFind, textToFind)
      .then(response => {
        setAgents(response.data)
        setLastPage(response.last_page)
        setPerPage(response.per_page)
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [currrentPage, agentToFind, textToFind])

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  return (
    <div style={{ padding: "20px" }}>
      <AgentsSearchBar
        agentToFind={agentToFind}
        setAgentToFind={setAgentToFind}
        textToFind={textToFind}
        setTextToFind={setTextToFind}
        setCurrrentPage={setCurrrentPage}
      />
      <AgentsTable
        agents={agents}
      />
      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
    </div>
  )
}

export default ShowAgents