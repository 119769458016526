import { useState, useEffect, useContext } from 'react'
import { Form, FormInput, FormButton } from "styles/form"

import { Send2FACode, AddIpToWhiteListService } from "services/apiRequests"
import { apiIntranet } from 'services/axios'
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from 'context/TranslationProvider'

const CHECK_2FA_URL = '/check2FA'

const TwoFactorAuth = (props) => {

  const auth = useContext(AuthProvider)
  const { t } = useContext(TranslationProvider)
  const [code, setTfaCode] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [addIpToWhiteList, setAddIpToWhiteList] = useState(false)

  const [apiResponse, setApiResponse] = useState({
    status: null, // 0: ok, 1: error en API, 2: error en request -->HABRÁ QUE CONVERTIRLO EN UN HOOK, para reusarlo
    errorCode: null,
    message: []
  })

  useEffect(() => {
    setErrMsg('')
  }, [code])

  const handleApiResponse = (e) => setApiResponse({
    ...apiResponse,
    status: e.status,
    errorCode: e.errorCode,
    message: e.message,
  })

  /* eslint-disable */
  useEffect(() => {
    Send2FACode({ loginParameters: props.loginParameters, fetchApiResponse: handleApiResponse, apiResponse: apiResponse })
  }, [])
  /* eslint-disable */

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await apiIntranet.post(CHECK_2FA_URL,
        { code: code }, {
        headers: {
          'Authorization': `Bearer ${props.loginParameters.accesToken}`
        }
      }
      )
      if (response?.data?.errorCode === 0) {
        props.setLoginParameters({
          is2FAValidated: 1,
        })
        if (addIpToWhiteList) {
          AddIpToWhiteListService({ loginParameters: auth })
        }
      }
      else {
        setErrMsg('código erróneo')
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password')
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }
    }
  }

  const handleCheckBoxChange = () => {
    setAddIpToWhiteList(!addIpToWhiteList)
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <h1 className="mb-3 fs-3 fw-normal">{t('two_factor_auth.main_message')}</h1>
        <FormInput
          type="number"
          size="lg"
          placeholder={t('two_factor_auth.place_holder')}
          autoComplete="off"
          className="position-relative"
          onChange={(e) => setTfaCode(e.target.value)}
          required
        />
        <label>
          <FormInput type="checkbox"
            value={addIpToWhiteList}
            onChange={handleCheckBoxChange}
          /> {t('two_factor_auth.checkbox_message')}
        </label>

        <h4>{errMsg}</h4>

        <div className="d-grid">
          <FormButton>{t('submit')}</FormButton>
        </div>
      </Form>
    </div>
  )
}

export default TwoFactorAuth
