import React, { useContext } from 'react'
import Alert from 'react-bootstrap/Alert'
import logo from 'logo.svg'
import TranslationProvider from 'context/TranslationProvider'
import { Form, FormInput, FormButton2 } from "styles/form"

const RenderChangePassword = (props) => {

  const { t } = useContext(TranslationProvider)

  return (
    <div>
      <Form onSubmit={props.handleSubmit}>
        <img className="App-logo"
          src={logo}
          alt="React Logo"
        />
        <h1 className="mb-3 fs-3 fw-normal">{t('change_password.change_password_form')}</h1>
        {
          props.pwd !== '' && props.pwd === props.newPwd &&
          <Alert variant="danger">
            {t('change_password.error1')}
          </Alert>
        }
        {
          props.newPwd !== '' && props.newPwdTwo !== '' && props.newPwdTwo !== props.newPwd &&
          <Alert variant="danger">
            {t('change_password.error2')}
          </Alert>
        }
        <FormInput
          type="password" size="lg"
          placeholder={t('change_password.current_password')}
          className="position-relative"
          autoComplete="off"
          onChange={(e) => props.setPwd(e.target.value)}
          value={props.pwd}
          required
        />
        <FormInput
          type="password"
          size="lg"
          placeholder={t('change_password.new_password')}
          autoComplete="off"
          className="position-relative"
          onChange={(e) => props.setNewPwd(e.target.value)}
          value={props.newPwd}
          required
        />
        <FormInput
          type="password"
          size="lg"
          placeholder={t('change_password.retype_the_new_password')}
          autoComplete="off"
          className="position-relative"
          onChange={(e) => props.setNewPwdTwo(e.target.value)}
          value={props.newPwdTwo}
          required
        />
        {props.errorMsg && props.errorMsg !== "password changed" &&
          <Alert variant="danger">
            {props.errorMsg}
          </Alert>
        }
        {props.errorMsg && props.errorMsg === "password changed" &&
          <Alert variant="success">
            {t('change_password.password_changed_OK')}
          </Alert>
        }
        <div>
          {!(props.errorMsg && props.errorMsg === "password changed") &&
            <FormButton2>{t('submit')}</FormButton2>
          }
        </div>
      </Form>
    </div>
  )
}

export default RenderChangePassword
