import { URL } from './urls';

export default function getTcResellers(accesToken, company_id, submanager_id = 0, manager_id = 0) {

  let companySelectedForApi = (company_id.value === undefined) ? 0 : company_id.value
  let managerSelectedForApi = (manager_id.value === undefined) ? 0 : manager_id.value
  let submanagerSelectedForApi = (submanager_id.value === undefined) ? 0 : submanager_id.value

  const apiURL = URL + `/teleconnect_resellers?company_id=${companySelectedForApi}&submanager_id=${submanagerSelectedForApi}&manager_id=${managerSelectedForApi}`

  return fetch(apiURL, {
    headers: {
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      const tempArray = [];
      if (response) {
        if (response.length) {
          response.forEach((element) => {
            const str = element.name.toLowerCase()
            const arr = str.split(" ")
            for (var i = 0; i < arr.length; i++) {
              arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ")
            tempArray.push({
              label: `${str2}`,
              value: element.id,
              iva: element.iva,
              irpf: element.irpf,
              commission: element.commission,
            });
          });
        } else {
          tempArray.push({
            label: `${response.name}`,
            value: response.id,
          });
        }
      }
      return tempArray
    })
}