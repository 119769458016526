import { useEffect, useState } from 'react'
import getTcCommissions from "services/getTcCommissions"

export default function useGetTcCommissions(accesToken, currrentPage, perPage, companySelected, submanagerSelected, resellerSelected,
  textToFind, monthSelected) {

  const [commissions, setCommissions] = useState([])
  const [error, setError] = useState(false)
  const [lastPage, setLastPage] = useState(1)
  const [totals, setTotals] = useState([])

  useEffect(() => {
    getTcCommissions(
      accesToken,
      currrentPage,
      perPage,
      companySelected,
      submanagerSelected,
      resellerSelected,
      textToFind,
      monthSelected.toISOString().slice(0, 7)
    ).then((commissions) => {
      setCommissions(commissions.paginator.data)
      setLastPage(commissions.paginator.last_page)
      setTotals(commissions.totals)
    })
      .catch(() => {
        setError(true)
      })
  }, [accesToken, currrentPage, perPage, companySelected, submanagerSelected, resellerSelected, textToFind, monthSelected])

  return {
    commissions,
    error,
    lastPage,
    totals
  }
} 