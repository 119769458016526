import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const InvoiceThankYouMsg = (props) => {
    
    const t = props.t;

    const styles = StyleSheet.create({
        titleContainer: {
            marginTop: 12
        },
        reportTitle: {
            fontSize: 12,
            textAlign: 'center',
            textTransform: 'uppercase',
        }
    });
    
    return (
        <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>{t('invoice.thankYouMsg')} </Text>
        </View>

    );
};

export default InvoiceThankYouMsg;