import api from 'services/axios'
import { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import MainDetail from './agent_detail/MainDetail'
import LocationDetail from './agent_detail/LocationDetail'
import TranslationProvider from 'context/TranslationProvider'

const ShowTeleconnectAgent = () => {

  const { t } = useContext(TranslationProvider)
  const navigate = useNavigate()
  const { id } = useParams()
  const [formParameters, setFormParameters] = useState({
    distribuidor: '',
    contactNameAndSurname: '',
    nombreLocutorio: '',
    empresa: '',
    web: '',
    telefono1: '',
    telefono2: '',
    email: '',
    direccion: '',
    localidad: '',
    provincia: '',
    codigoPostal: '',
    cuenta: '',
    fechaAlta: '',
    fechaModificado: '',
    commission: 0,
    credito: 0,
    creditoLimite: 0,
  })
  const goBack = async (e) => {
    e.preventDefault()
    navigate('/teleconnect_agents_page')
  }

  /* eslint-disable */
  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    const getbankEntryById = async () => {
      const response = await api.get(`/teleconnect_accounts/${id}`)
      setFormParameters({
        ...formParameters,
        contactNameAndSurname: response.data.nombreContacto + ' ' + response.data.apellidosContacto,
        nombreLocutorio: response.data.nombreLocutorio,
        empresa: response.data.empresa,
        cuenta: response.data.cuenta,
        web: response.data.web,
        distribuidor: response.data.reseller?.nombre,
        telefono1: response.data.telefono1,
        telefono2: response.data.telefono2,
        email: response.data.email,
        fechaAlta: response.data.fechaAlta,
        fechaModificado: response.data.fechaModificado,
        direccion: response.data.direccion,
        localidad: response.data.localidad,
        provincia: response.data.provincia,
        codigoPostal: response.data.codigoPostal,
        commission: response.data.commission.commission,
        credito: response.data.credito,
        creditoLimite: response.data.creditoLimite,
      })
    }
    getbankEntryById()
  }, [])

  return (
    <div style={{ padding: "20px" }}>
      <h3 style={{ "marginBottom": "20px" }}>{t('commercial_network.agent_detail')}</h3>
      <Tabs
        defaultActiveKey="summary"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="summary" title={t('summary')}>
          <MainDetail
            formParameters={formParameters}
            t={t}
          />
        </Tab>
        <Tab eventKey="direccion" title={t('location.address')}>
          <LocationDetail
            formParameters={formParameters}
            t={t}
          />
        </Tab>
      </Tabs>

      <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

      <Button style={{ margin: "10px", padding: "10px" }} onClick={goBack} type='submit' className='btn btn-primary'>{t('back')}</Button>

    </div>
  )
}
export default ShowTeleconnectAgent