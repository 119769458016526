import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"

//components
import Pagination from "components/controls/Pagination"
import TcRecharguesTable from "components/teleconnect/rechargues/TcRecharguesTable"
import TcSearchBar from "components/controls/SearchBar/TcSearchBar"
//context - config
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"
import { rechargesInitialStartDate, rechargesInitialEndDate } from "config/sinceUntilDatePicker"
// hooks
import useSinceUntilDatePicker from 'hooks/useSinceUntilDatePicker'
// services
import getTcRechargues from "services/getTcRechargues"

const TeleconnectRechargues = () => {

  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const [rechargues, setRechargues] = useState([])


  const [currrentPage, setCurrrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [searchParameters, setSearchParameters] = useState({
    company: 0,
    manager: 0,
    submanager: 0,
    reseller: 0,
    text: "",
    perPage: {
      value: 10,
      label: 10,
    },
    onlyActive: true,
  })

  const [totals, setTotals] = useState([])

  const { startDate, endDate, changeStartDate, changeEndDate } = useSinceUntilDatePicker({
    initialStartDate: rechargesInitialStartDate, initialEndDate: rechargesInitialEndDate
  })

  useEffect(() => {   /* eslint-disable */
    getTcRechargues(
      auth.accesToken,
      currrentPage,
      searchParameters.perPage.value,
      searchParameters.company,
      searchParameters.reseller,
      searchParameters.text,
      startDate.toISOString().slice(0, 10),
      endDate.toISOString().slice(0, 10),
      searchParameters.submanager,
      searchParameters.manager,
    ).then((rechargues) => {
      setRechargues(rechargues.paginator.data)
      setLastPage(rechargues.paginator.last_page)
      setTotals(rechargues.totals)
    })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [auth.accesToken, currrentPage, searchParameters.perPage.value, searchParameters.company, searchParameters.reseller, searchParameters.text,
    startDate, endDate, searchParameters.submanager, searchParameters.manager])

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  return (
    <div style={{ padding: "20px" }}>
      <TcSearchBar
        t={t}
        searchParameters={searchParameters}
        setSearchParameters={setSearchParameters}
        setCurrrentPage={setCurrrentPage}
        lookForText={true}
        showResellers={true}
        showPaginator={true}
        showActiveFilter={false}
        startDate={startDate}
        changeStartDate={changeStartDate}
        endDate={endDate}
        changeEndDate={changeEndDate}
        showSinceUntilDatePicker={true}
      />
      <TcRecharguesTable
        rechargues={rechargues}
        totals={totals}
      />
      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
    </div>
  )
}

export default TeleconnectRechargues
