import React, { useContext } from 'react'
import TranslationProvider from 'context/TranslationProvider'

const Home = () => {

  const { t } = useContext(TranslationProvider)

  return (
    <section className="App-content">
      {t('pages.homeWelcomeMsg')}
    </section>
  )
}

export default Home