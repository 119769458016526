import { URL } from './urls';

export default function getMovileurosAgentsTable(accesToken, currrentPage, perPage, agentToFind, textToFind) {
  const apiURL = URL + `/react_agents?page=${currrentPage}&per_page=${perPage}&income_code=${agentToFind}&textToFind=${textToFind}`

  return fetch(apiURL, {
    headers: {
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response
    })
}