import React, { useEffect, useState, useContext } from 'react';
import api from 'services/axios';
import Container from 'react-bootstrap/Container'
import { bankConcepts } from 'services/bankConcepts';
import { useNavigate, useParams } from 'react-router-dom';
import ReactAsyncSelect from "components/controls/ReactAsyncSelect";
import Select from "react-select";
import getMovileurosAgents from 'services/getMovileurosAgents';
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from 'context/TranslationProvider'
import { FormLabel, FormButton2, FormHead } from "styles/form"

const EditBankEntry = () => {

  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()
  const { id } = useParams()
  const [formParameters, setFormParameters] = useState({
    initialConcept: [],
    initialAgent: [],
    bank: '',
    date: '',
    comment: '',
    agentId: '',
    bankId: null,
    conceptId: null,
    amount: 0
  })
  const [errMsg, setErrMsg] = useState('');
  const [movileurosAgents, setMovileurosAgents] = useState([])

  const update = async (e) => {
    e.preventDefault();

    try {
      await api.put(`/bank_entries/${id}`, {
        //date: date,
        amount: formParameters.amount,
        bankComment: formParameters.comment,
        bankConceptId: formParameters.conceptId,
        agentId: formParameters.agentId,
        bankId: formParameters.bankId,
      }, {
        headers: {
          'Authorization': `Bearer ${auth.accesToken}`
        }
      })
      navigate('/bank_entries')
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
    }
  }

  const goBack = async (e) => {
    e.preventDefault();
    navigate('/bank_entries')
  }

  /* eslint-disable */
  useEffect(() => {
    const getbankEntryById = async () => {
      const response = await api.get(`/bank_entries/${id}`)
      setFormParameters({
        ...formParameters,
        initialAgent: {
          label: response.data.agent ? response.data.agent.income_code : null,
          value: response.data.agent ? response.data.agent.id : null
        },
        initialConcept: {
          label: response.data.concept ? response.data.concept.name : null,
          value: response.data.concept ? response.data.concept.id : null
        },
        date: response.data.date,
        agentId: response.data.agent ? response.data.agent.id : null,
        conceptId: response.data.concept ? response.data.concept.id : null,
        bank: response.data.bank.name,
        bankId: response.data.bank.id,
        comment: response.data.comment,
        amount: parseFloat(response.data.amount).toFixed(2)
      })
    }
    getbankEntryById()
  }, [])
  /* eslint-disable */

  useEffect(() => {
    getMovileurosAgents(auth.accesToken).then(movileurosAgents => setMovileurosAgents(movileurosAgents))
  }, [auth.accesToken])

  // I have to wait for the initialAgent to load before randerizing
  if (formParameters.initialAgent.length === 0) return;

  return (
    <Container>
      <FormHead>{t('banks.edit_bank_entry')}</FormHead>
      <form>
        <div>
          <FormLabel>{t('banks.bank')}</FormLabel>
          <input
            value={formParameters.bank}
            type='text'
            className='form-control'
            disabled
          />
        </div>
        <div>
          <FormLabel>{t('banks.date')}</FormLabel>
          <input
            value={formParameters.date}
            type='text'
            className='form-control'
            disabled
          />
        </div>
        <div>
          <FormLabel>{t('commercial_network.code')}</FormLabel>
          <Select
            options={movileurosAgents}
            onChange={(e) => setFormParameters({
              ...formParameters,
              agentId: e.value
            })}
            //onInputChange={() => setWantedAgent('')}
            isClearable
            defaultValue={formParameters.initialAgent}
          />
        </div>
        <div>
          <FormLabel>{t('banks.concept')}</FormLabel>
          <ReactAsyncSelect
            initialOption={formParameters.initialConcept}
            handleChange={(e) => setFormParameters({
              ...formParameters,
              conceptId: e.value
            })}
            fetchData={bankConcepts}
          />
        </div>
        <div>
          <FormLabel>{t('banks.comment')}</FormLabel>
          <input
            value={formParameters.comment}
            onChange={(e) => setFormParameters({
              ...formParameters,
              comment: e.target.value
            })}
            type='text'
            className='form-control'
          />
        </div>
        <div>
          <FormLabel>{t('banks.amount')}</FormLabel>
          <input
            value={formParameters.amount}
            onChange={(e) => setFormParameters({
              ...formParameters,
              amount: e.target.value
            })}
            type='number'
            className='form-control'
            disabled
          />
        </div>
        <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

        <FormButton2 onClick={update}>{t('update')}</FormButton2>
        <FormButton2 onClick={goBack}>{t('back')}</FormButton2>
      </form>
    </Container>
  );
};
export default EditBankEntry;