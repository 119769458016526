import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


const InvoiceNo = (props) => {

    const t = props.t;
    const invoice = props.invoice;

    const styles = StyleSheet.create({
        invoiceNoContainer: {
            marginTop: 5,
            justifyContent: 'flex-start',
        },
        /* leftOfRow: {
            width: '25%',
        },
        rigthOfRow: {
            width: '75%',
        }, */

    });
    /*
                <View >
                    <Text style={styles.leftOfRow}>{t('invoice.invoiceRef')} </Text>
                    <Text style={styles.rigthOfRow}>{invoice.invoice_no}</Text>
                </View >
    
    
                <View style={styles.invoiceDateContainer}>
                    <Text style={styles.leftOfRow}>{t('invoice.Date')} </Text>
                    <Text style={styles.rigthOfRow}>{invoice.trans_date}</Text>
                </View >
    */
    return (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text >{t('invoice.Date') + ': ' + invoice.trans_date} </Text>
                <Text >{t('invoice.invoiceRef') + ': ' + invoice.invoice_no} </Text>
            </View >
        </Fragment>
    );
};

export default InvoiceNo;