import React from 'react'
import { useEffect, useContext } from 'react'

import RenderBrowserRouter from './RenderBrowserRouter'
import TranslationProvider from 'context/TranslationProvider'
import AuthProvider from 'context/AuthProvider'

const AuthenticatedUser = () => {

  const auth = useContext(AuthProvider)
  const { i18n } = useContext(TranslationProvider)
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  // I initialize the user language with the value read from the auth api
  /* eslint-disable */
  useEffect(() => {
    changeLanguage(auth.language)
  }, [auth.language])
  /* eslint-disable */

  return (
    <RenderBrowserRouter
      changeLanguage={changeLanguage}
    >
    </RenderBrowserRouter>
  )
}

export default AuthenticatedUser