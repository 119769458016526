//import { URL } from './urls';
import { apiIntranet } from './axios';

const editReseller = async (irpf, iva, nombre, email, password, telefono, isNeeded2FA, id, accesToken, setErrMsg) => {

  try {
    const response = await apiIntranet.put(`/teleconnect_resellers/${id}`, {
      irpf: `${irpf}`,
      iva: `${iva}`,
      nombre: `${nombre}`,
      email: `${email}`,
      password: `${password}`,
      telefono: `${telefono}`,
      isNeeded2FA: `${isNeeded2FA}`,
    }, {
      headers: {
        'Authorization': `Bearer ${accesToken}`
      }
    }
    );
    return response;
  } catch (err) {
    if (!err?.response) {
      setErrMsg('No Server Response')
    } else if (err.response?.status === 400) {
      setErrMsg('Missing Username or Password')
    } else if (err.response?.status === 401) {
      setErrMsg('Unauthorized')
    } else {
      setErrMsg('Login Failed')
    }
    return err;
  }
}

export { editReseller }
