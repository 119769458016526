import React, { useEffect, useState } from 'react'
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai"

const SortTable = ({ field, sortField, sortOrder, setSortField, setSortOrder, setCurrrentPage }) => {

  const [upIconColor, setUpIconColor] = useState("white")
  const [downIconColor, setDownIconColor] = useState("white")


  useEffect(() => {
    if (field === sortField) {
      if (sortOrder === "asc") {
        setUpIconColor("#f598ad") 
        setDownIconColor("white")
      }
      else {
        setUpIconColor("white") 
        setDownIconColor("#f598ad")
      }
    }
    else {
      setUpIconColor("white")
      setDownIconColor("white")
    }
  }, [field, sortField, sortOrder])

  const handleClick = (e) => {
    setCurrrentPage(1)
    setSortField(field)
    setSortOrder(e)
  }


  return (
    <>
      <AiFillCaretUp
        style={{ "marginLeft": "5px", "color": upIconColor }}
        onClick={(e) => handleClick("asc")}
      />
      <AiFillCaretDown
        style={{ "marginLeft": "5px", "color": downIconColor }}
        onClick={(e) => handleClick("desc")}
      />
    </>
  )
}

export default SortTable