import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const InvoiceTableHeader = (props) => {

    const t = props.t;
    
    const borderColor = '#3778C2'
    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
            borderBottomColor: '#3778C2',
            backgroundColor: '#3778C2',
            color: '#fff',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 24,
            textAlign: 'center',
            fontStyle: 'bold',
            flexGrow: 1,
        },
        description: {
            width: '60%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        qty: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        rate: {
            width: '15%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        amount: {
            width: '15%'
        },
    });

    return (
        <View style={styles.container}>
            <Text style={styles.description}>{t('invoice.itemDescription')}</Text>
            <Text style={styles.qty}>{t('invoice.qty')}</Text>
            <Text style={styles.rate}>{t('invoice.price')}</Text>
            <Text style={styles.amount}>{t('invoice.amount')}</Text>
        </View>
    );
};

export default InvoiceTableHeader;