
import axios from 'axios';
import {URL} from '../services/urls';


export default axios.create({
    baseURL: URL
});

export const apiIntranet = axios.create({
    baseURL: URL
});

/* export const axiosPrivate = axios.create({
    baseURL: INTRANET_LOCAL_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
}); */

