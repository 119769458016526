import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "guest": {
        "title": " WELCOME TO MOVILEUROS",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipi",
        "body_title": "Coming Soon",
        "body_desc": "Lorem ipsum dolor sit amet, consectetur adipi"
      },
      "commissions": {
        "commissions": "Commissions",
        "minutes": " Minutos",
        "sales": " Sales",
        "incomes": " Incomes",
        "calls": " Calls",
      },
      "pages": {
        "home": "Home",
        "homeWelcomeMsg": "Real knowledge is to know the extent of one's ignorance",
        "confucius": "Confucius",
        "sales": "Sales",
        "invoices": "Invoices",
        "about": "About Page",
        "commercial_network": "Commercial Network",
        "banks": "Banks",
        "agents": "Agents",
        "resellers": "Resellers",
        "rechargues": "Rechargues",
        "credits": "Credits",
        "bank_entries": "Bank Entries"
      },
      "commercial_network": {
        "agent": "Agent",
        "agent_detail": "Agent Detail",
        "commercial": "Commercial",
        "commission": "Commission",
        "company": "Company",
        "contacto": "Contacto",
        "tax_name": "Nombre Fiscal",
        "code": "Code",
        "name": "Name",
        "address": "Address",
        "city": "city",
        "province": "Province",
        "telefono1": "Phone 1",
        "telefono2": "Phone 2",
        "balance": "Balance",
        "credit": "Credit",
        "agent_to_search": "Agent to search",
        "search_in_any_field": "Search in any field",
        "edit_agent": "Edit Agent",
        "edit_reseller": "Edit Reseller",
      },
      "banks": {
        "deletetext1": `You are going to delete a bank statement from "`,
        "deletetext2": `" in the amount of `,
        "bank": "Bank",
        "date": "Date",
        "hour": "Hour",
        "code": "Code",
        "concept": "Concept",
        "comment": "Comment",
        "amount": "Amount",
        "bank_to_search": "Bank",
        "create_bank_entry": "Create Bank Entry",
        "edit_bank_entry": "Edit Bank Entry",
        "method": "Method",
        "balance": "Balance",
      },
      "user": {
        "name": "Name",
        "phone": "Phone",
        "email": "Email",
        "is_first_login": "Is First Login",
        "isNeeded2FA": "Is Needed 2FA",
        "notification_system": "Notification System",
        "printer": "Pinter",
        "created_at": "Discharge Date",
        "updated_at": "Last Activity",
        "acountType": "Acount Type",
        "commissions_retrieve_method": "Commissions Retrieve Method",
      },
      "location": {
        "address": "Address",
        "city": "City",
        "state": "State",
        "zip_code": "Zip Code",
      },
      "two_factor_auth": {
        "main_message": "Please intro your security code",
        "place_holder": "Security Code",
        "checkbox_message": "Remember my IP for 30 days",
      },
      "graphs": {
        "week": "Week",
        "sales": "Sales",
        "recharges": "Recharges",
        "minutes": "Minutes",
        "company_to_search": "Company",
        "reseller_to_search": "Reseller",
        "submanager_to_search": "Submanager",
        "manager_to_search": "Manager",
      },
      "invoice": {
        "Date": "Date",
        "invoiceRef": "Invoice Ref.",
        "billTo": "Bill To:",
        "itemDescription": "Item Description",
        "qty": "Qty",
        "price": "Price",
        "amount": "Amount",
        "thankYouMsg": "*** Thank You ***",
      },
      "change_password": {
        "change_password_form": "Change Password",
        "password": "Password",
        "password_changed_OK": "Password changed successfully",
        "error1": "Current Password and New Password can not be equal",
        "error2": "New Password is not properly rewritten",
        "current_password": "Current Password",
        "new_password": "New Password",
        "retype_the_new_password": "Retype the New Password",
      },
      "validation_errors": {
        "credit": "Credit must be greater than or equal to 0",
        "date": "Enter a valid datye: YYYY-MM-DD",
        "email": "This field must be an email",
        "notZeroNumber": "Enter a number other than zero",
        "required": "This field cannot be left empty",
        "iva": "Allowed values: 0 and 21",
        "irpf": "Allowed values: 0 and 15",
      },
      "deleteRechargeText1": `You are going to delete a credit statement from "`,
      "deleteRechargeText2": `" in the amount of `,
      "create_credit_entry": "Create Credit Entry",
      "approvedBy": "Approved by",
      "days": "Days",
      "close": "Close",
      "delete": "Delete",
      "signOut": "Sign out",
      "update": "Update",
      "store": "Store",
      "back": "Back",
      "clean": "Clean",
      "settings": "Settings",
      "submit": "Submit",
      "summary": "Summary",
      "security": "Security",
      "daily": "Daily",
      "weekly": "Weekty",
      "monthly": "Monthly",
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "November": "November",
      "December": "December",
    }
  },
  es: {
    translation: {
      "Welcome to React": "Bienvenue à React et react-i18next",
      "guest": {
        "title": " ESP",
        "desc": "ESP",
        "body_title": "ESP",
        "body_desc": "ESP"
      },
      "commissions": {
        "commissions": "Comisiones",
        "minutes": " Minutos",
        "sales": " Ventas",
        "incomes": " Ingresos",
        "calls": " Llamadas",
      },
      "pages": {
        "home": "Inicio",
        "homeWelcomeMsg": "El verdadero conocimiento es conocer el alcance de la propia ignorancia",
        "confucius": "Confucio",
        "sales": "Ventas",
        "invoices": "Facturas",
        "about": "Sobre Nosotros",
        "commercial_network": "Red Comercial",
        "banks": "Bancos",
        "agents": "Agentes",
        "resellers": "Comerciales",
        "rechargues": "Recargas",
        "credits": "Créditos",
        "bank_entries": "Apuntes Bancarios"
      },
      "commercial_network": {
        "agent": "Agente",
        "agent_detail": "Detalle del Agente",
        "commercial": "Comercial",
        "commission": "Comisión",
        "company": "Empresa",
        "contacto": "Contacto",
        "tax_name": "Nombre Fiscal",
        "code": "Código",
        "name": "Nombre",
        "address": "Dirección",
        "city": "Población",
        "province": "Provincia",
        "telefono1": "Teléfono 1",
        "telefono2": "Teléfono 2",
        "balance": "Saldo",
        "credit": "Crédito",
        "agent_to_search": "Agente a buscar",
        "search_in_any_field": "Buscar en cualquier campo",
        "edit_agent": "Editar Agente",
        "edit_reseller": "Editar Reseller",
      },
      "banks": {
        "deletetext1": `Va a borrar un apunte del banco "`,
        "deletetext2": `" por un importe de `,
        "bank": "Banco",
        "date": "Fecha",
        "hour": "Hora",
        "code": "Código",
        "concept": "Concepto",
        "comment": "Comentario",
        "amount": "Importe",
        "bank_to_search": "Banco",
        "create_bank_entry": "Crear Apunte Bancario",
        "edit_bank_entry": "Editar Apunte Bancario",
        "method": "Método",
        "balance": "Saldo",
      },
      "user": {
        "name": "Nombre",
        "phone": "Teléfono",
        "email": "Email",
        "is_first_login": "Primer Login",
        "isNeeded2FA": "Necesita 2FA",
        "notification_system": "Sistema de Notificaciones",
        "printer": "Impresora",
        "created_at": "Fecha de Alta",
        "updated_at": "Última Actividad",
        "acountType": "Tipo de Cuenta",
        "commissions_retrieve_method": "Sistema de pago de Comisiones",
      },
      "location": {
        "address": "Dirección",
        "city": "Población",
        "state": "Provincia",
        "zip_code": "Código Postal",
      },
      "two_factor_auth": {
        "main_message": "Popr favor introduce tu Código de Seguridad",
        "place_holder": "Código de Seguridad",
        "checkbox_message": "Recordar mi IP durante 30 días",
      },
      "graphs": {
        "week": "Semana",
        "sales": "Ventas",
        "recharges": "Recargas",
        "minutes": "Minutos",
        "company_to_search": "Empresa",
        "reseller_to_search": "Distribuidor",
        "submanager_to_search": "Submanager",
        "manager_to_search": "Manager",
      },
      "invoice": {
        "Date": "Fecha",
        "invoiceRef": "Factura Nº",
        "billTo": "Facturar a:",
        "itemDescription": "Descripción",
        "qty": "Cantidad",
        "price": "Precio",
        "amount": "Importe",
        "thankYouMsg": "*** Muchas gracias ***",
      },
      "change_password": {
        "change_password_form": "Cambio de contraseña",
        "password": "Contraseña",
        "password_changed_OK": "Contraseña cambiada satisfactoriamente",
        "error1": "La Contraseña Nueva y la Actual no pueden ser iguales",
        "error2": "La Nueva Contraseña se ha reescrito mal",
        "current_password": "Contraseña Actual",
        "new_password": "Nueva Contraseña",
        "retype_the_new_password": "Repita la Nueva Contraseña",
      },
      "validation_errors": {
        "credit": "El cédito debe ser mayor o igual que 0",
        "date": "Introduzca una fecha válida: AAAA-MM-DD, por ejemplo 2023-12-28",
        "email": "Este campo debe ser un correo electrónico",
        "notZeroNumber": "Introduzca un número distinto a cero",
        "required": "Este campo no puede quedar vacío",
        "iva": "Valores permitidos: 0 y 21",
        "irpf": "Valores permitidos: 0 y 15",
      },
      "deleteRechargeText1": `Va a borrar un crédito del agente "`,
      "deleteRechargeText2": `" por un importe de `,
      "create_credit_entry": "Crear Apunte de Crédito",
      "approvedBy": "Autorizado por",
      "days": "Días",
      "close": "Cerrar",
      "delete": "Borrar",
      "signOut": "Salir",
      "update": "Guardar",
      "store": "Guardar",
      "back": "Volver",
      "clean": "Limpiar",
      "settings": "Ajustes",
      "submit": "Enviar",
      "summary": "Resumen",
      "security": "Seguridad",
      "daily": "Diario",
      "weekly": "Semanal",
      "monthly": "Mensual",
      "January": "Enero",
      "February": "Febrero",
      "March": "Marzo",
      "April": "Abril",
      "May": "Mayo",
      "June": "Junio",
      "July": "Julio",
      "August": "Agosto",
      "September": "Septiembre",
      "October": "Octubre",
      "November": "Noviembre",
      "December": "Diciembre",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;