import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import Container from 'react-bootstrap/Container'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

//components
import Chart from 'components/teleconnect/sales/Chart'
import TcSearchBar from "components/controls/SearchBar/TcSearchBar"
//context - config
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from 'context/TranslationProvider'
// services
import getTcSales from 'services/getTcSales'

const TeleconnectSales = () => {

  const { t } = useContext(TranslationProvider)
  const { i18n } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const [searchParameters, setSearchParameters] = useState({
    company: 0,
    manager: 0,
    submanager: 0,
    reseller: 0,
    text: "",
  })

  const [teleconnectSales, setTeleconnectSales] = useState([])

  useEffect(() => {   /* eslint-disable */
    getTcSales(auth.accesToken, searchParameters.company, searchParameters.reseller, searchParameters.submanager, searchParameters.manager)
      .then(teleconnectSales => setTeleconnectSales(teleconnectSales))
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [auth.accesToken, searchParameters.company, searchParameters.reseller, searchParameters.submanager, searchParameters.manager])

  return (
    <Container style={{ padding: "20px" }}>
      <TcSearchBar
        t={t}
        searchParameters={searchParameters}
        setSearchParameters={setSearchParameters}
        lookForText={false}
        showResellers={true}
        showPaginator={false}
      />
      <h2 style={{textAlign: "center" }} > {t('pages.sales')}</h2>
      <Tabs
        defaultActiveKey="daily"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="daily" title={t('daily')}>
          <Chart
            data={teleconnectSales.dataByDay}
            xlabel='date'
            t={t}
            i18n={i18n}
            type={'daily'}
          />
        </Tab>
        <Tab eventKey="weekly" title={t('weekly')}>
          <Chart
            data={teleconnectSales.dataByWeek}
            xlabel='week'
            t={t}
            i18n={i18n}
            type={'weekly'}
          />
        </Tab>
        <Tab eventKey="monthly" title={t('monthly')}>
          <Chart
            data={teleconnectSales.dataByMonth}
            xlabel='month'
            t={t}
            i18n={i18n}
            type={'monthly'}
          />
        </Tab>
      </Tabs>
    </Container>
  )
}

export default TeleconnectSales