import api from './axios';

export const bankConcepts = async () => {
  try {
    const response = await api.get('/bank_concepts_react');
    const tempArray = [];
    if (response.data) {
      if (response.data.length) {
        response.data.forEach((element) => {
          tempArray.push({
            label: `${element.name}`,
            value: element.id,
          });
        });
      } else {
        tempArray.push({
          label: `${response.data.name}`,
          value: response.data.id,
        });
      }
    }

    return (tempArray);
  } catch (err) {
    console.error(err);
  }
}