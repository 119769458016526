import React from 'react';
import Container from 'react-bootstrap/Container';

const Settings = () => {
  return (
    <Container id="main-container">
      <div className="text-center p-5 w-100">
        Futuros ajustes
      </div>
    </Container>
  );
};

export default Settings;