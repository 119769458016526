import { useEffect, useState } from 'react'
import getTcResellers from 'services/getTcResellers'

export default function useGetTcResellers(accesToken, companySelected, submanager_id = 0, manager_id = 0) {

  const [resellers, setResellers] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    getTcResellers(accesToken, companySelected, submanager_id, manager_id)
      .then(resellers => setResellers(resellers))
      .catch(() => {
        setError(true)
      })
  }, [accesToken, companySelected, manager_id, submanager_id])

  return {
    error,
    resellers
  }
} 