import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const BillTo = (props) => {

    const t = props.t;
    const invoice = props.invoice;

    const styles = StyleSheet.create({
        headerContainer: {
            marginTop: 15,
            justifyContent: 'flex-start',
        },
        billToHead: {
            marginBottom: 5,
            fontFamily: 'Helvetica-Oblique'
        },
        billToContent: {
            marginLeft: 12,
            fontFamily: 'Helvetica-Oblique'
        },
    });

    return (
        <View style={styles.headerContainer}>
            <Text style={styles.billToHead}>{t('invoice.billTo')} </Text>
            <Text style={styles.billToContent}>{invoice.fullname}</Text>
            <Text style={styles.billToContent}>{invoice.address}</Text>
            <Text style={styles.billToContent}>{invoice.phone}</Text>
            <Text style={styles.billToContent}>{invoice.email}</Text>
        </View>
    );
};

export default BillTo;