import Card from 'react-bootstrap/Card'; 
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const LocationDetail = (props) => {

  const t = props.t;

  return (
    <Container>
      <Row className="justify-content-md-center mb-3">
        <Col xs={4}>
          <Card>
            <Card.Body>
              <Card.Title>{t('location.address')}</Card.Title>
              <Card.Text>{props.formParameters.direccion}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('location.city')}</Card.Title>
              <Card.Text>{props.formParameters.localidad}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('location.state')}</Card.Title>
              <Card.Text>{props.formParameters.provincia}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={2}>
          <Card>
            <Card.Body>
              <Card.Title>{t('location.zip_code')}</Card.Title>
              <Card.Text>{props.formParameters.codigoPostal}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
  );
};
export default LocationDetail;