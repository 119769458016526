import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import { /* AiFillEdit,  */AiFillEye } from "react-icons/ai"
// context
import AuthProvider from "context/AuthProvider"
import TranslationProvider from 'context/TranslationProvider'
// styles
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const TcAgentsTable = (props) => {

  const auth = useContext(AuthProvider)
  const { t } = useContext(TranslationProvider)

  //console.log(props.teleconnectAgents)
  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr type="center">
            {!auth.roles.includes("Reseller") && <Th>{t('commercial_network.commercial')}</Th>}
            <Th>{t('commercial_network.code')}</Th>
            <Th>{t('commercial_network.name')}</Th>
            <Th>{t('commercial_network.address')}</Th>
            <Th>{t('commercial_network.city')}</Th>
            <Th>{t('commercial_network.province')}</Th>
            <Th>{t('commercial_network.commission')}</Th>
            <Th>{t('commercial_network.credit')}</Th>
            <Th>{t('commercial_network.balance')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.teleconnectAgents.map((agent) => (
            <Tr key={agent.cuenta} type={(agent?.estado === "SUSPENDED") ? "alert" : ""}>
              {!auth.roles.includes("Reseller") && <Td> {agent.reseller.nombre} </Td>}
              <Td> {agent.cuenta} </Td>
              <Td> {agent.nombreContacto + ' ' + agent.apellidosContacto} </Td>
              <Td> {agent.direccion.substring(0, 30)} </Td>
              <Td> {agent.localidad} </Td>
              <Td> {agent.provincia} </Td>
              <Td type="center"> {(parseFloat(agent.commission.commission) * 100).toFixed(2) + ' %'} </Td>
              <Td type="number"> {parseFloat(agent.creditoLimite).toFixed(2)} </Td>
              <Td type="number"> {parseFloat(agent.credito).toFixed(2)} </Td>
              {/*<Td>
                <Link to={`/commercial_network/agents/edit/${agent.id}`}>  <AiFillEdit /> </Link>
              </Td> */}
              <Td>
                <Link to={`/teleconnect_agents_page/show/${agent.cuenta}`}>  <AiFillEye /> </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default TcAgentsTable