import React, { useContext, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import TranslationProvider from 'context/TranslationProvider'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { FormLabel, FormButton2, FormError, FormHead } from "styles/form"

const EditAgentForm = ({ formParameters, setFormParameters, goBack, update, errMsg }) => {

  const { t } = useContext(TranslationProvider)

  const schema = yup.object({
    credit: yup.number().min(0).integer().required(),
    email: yup.string().email().required(),
  }).required()

  const { reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    let defaultValues = {}
    defaultValues.credit = Math.round(formParameters.agentCredit)
    defaultValues.email = formParameters.email
    reset({ ...defaultValues })
  }, [reset, formParameters.agentCredit, formParameters.email])

  const onSubmit = () => update()

  return (
    <Container>
      <FormHead>{t('commercial_network.edit_agent')}</FormHead>
      <form>
        <div>
          <FormLabel>{t('commercial_network.commercial')}</FormLabel>
          <input
            value={formParameters.sellerName}
            type='text'
            className='form-control'
            disabled
          />
        </div>
        <div>
          <FormLabel>{t('commercial_network.code')}</FormLabel>
          <input
            value={formParameters.incomeCode}
            type='text'
            className='form-control'
            disabled
          />
        </div>
        <div>
          <FormLabel>{t('user.name')}</FormLabel>
          <input
            value={formParameters.agentNameAndSurname}
            type='text'
            className='form-control'
            disabled
          />
        </div>
        <div>
          <FormLabel>{t('user.phone')}</FormLabel>
          <input
            value={formParameters.phone}
            onChange={(e) => setFormParameters({
              ...formParameters,
              phone: e.target.value
            })}
            type='text'
            className='form-control'
          />
        </div>
        <div>
          <FormLabel>{t('user.email')}</FormLabel>
          <input
            {...register("email")}
            value={formParameters.email}
            onChange={(e) => setFormParameters({
              ...formParameters,
              email: e.target.value
            })}
            type='text'
            className='form-control'
          />
          {errors.email && <FormError>{t('validation_errors.email')}</FormError>}
        </div>
        <div>
          <FormLabel>{t('location.address')}</FormLabel>
          <input
            value={formParameters.address}
            type='text'
            className='form-control'
            onChange={(e) => setFormParameters({
              ...formParameters,
              address: e.target.value
            })}
          />
        </div>
        <div>
          <FormLabel>{t('location.city')}</FormLabel>
          <input
            value={formParameters.city}
            type='text'
            className='form-control'
            onChange={(e) => setFormParameters({
              ...formParameters,
              city: e.target.value
            })}
          />
        </div>
        <div>
          <FormLabel>{t('commercial_network.credit')}</FormLabel>
          <input
            {...register("credit")}
            value={Math.round(formParameters.agentCredit)}
            onChange={(e) => setFormParameters({
              ...formParameters,
              agentCredit: e.target.value
            })}
            className='form-control'
          />
          {errors.credit && <FormError>{t('validation_errors.credit')}</FormError>}
        </div>

        <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

        <FormButton2 onClick={handleSubmit(onSubmit)} >{t('update')}</FormButton2>
        <FormButton2 onClick={goBack} >{t('back')}</FormButton2>

      </form>
    </Container>
  )
}
export default EditAgentForm