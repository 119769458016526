import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


const InvoiceSummary = ({ totals, reseller, submanagerSelected}) => {

    let ingresos = (totals !== undefined) ? totals.totalIngresos : 0
    let baseImponible = ingresos / 1.21
    // al resellet Tino no se le aplica retenciones en la base imponible
    if (reseller && reseller.value === 54) baseImponible = ingresos
    let comisionPorcetaje = (reseller) ? (parseFloat(reseller.commission) * 100).toFixed(0) : 6
    let irpfPorcetaje = (reseller) ? (parseFloat(reseller.irpf) * 100) : 0
    let ivaPorcetaje = (reseller) ? (parseFloat(reseller.iva) * 100) : 21
    if (submanagerSelected && submanagerSelected.value === 5) comisionPorcetaje = 9
    if (submanagerSelected && submanagerSelected.value === 6) {
        comisionPorcetaje = 5
        ivaPorcetaje = 0
        baseImponible = ingresos
    }
    let comision = baseImponible * comisionPorcetaje / 100
    let irpf = comision * irpfPorcetaje / 100
    let iva = comision * ivaPorcetaje / 100
    let importe = comision + iva - irpf

    const borderColor = '#3778C2'
    const styles = StyleSheet.create({
        titleContainer: {
            marginTop: 30,
            borderTopWidth: 2,
            borderTopColor: '#3778C2',
        },
        reportTitle: {
            marginTop: 30,
            marginBottom: 30,
            fontSize: 15,
            textAlign: 'center',
            textTransform: 'uppercase',
        },
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginLeft: 120,
            borderWidth: 1,
            borderColor: '#3778C2',
            width: '50%',
            //alignItems: 'center'
        },
        row: {
            flexDirection: 'row',
            borderBottomColor: '#3778C2',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 24,
            fontStyle: 'bold',
        },
        fisrtColumn: {
            width: '50%',
            textAlign: 'left',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            paddingLeft: 8,
        },
        secondColumn: {
            width: '18%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
        thirdColumn: {
            width: '32%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
    });

    return (
        <Fragment>
            <View style={styles.titleContainer}>
                <Text style={styles.reportTitle}>Detalle de la Factura</Text>
            </View>
            <View style={styles.tableContainer} >
                <View style={styles.row} >
                    <Text style={styles.fisrtColumn}>Ingresos</Text>
                    <Text style={styles.secondColumn}> </Text>
                    <Text style={styles.thirdColumn}>
                        {Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ingresos)}
                    </Text>
                </View>
                <View style={styles.row} >
                    <Text style={styles.fisrtColumn}>Base imponible</Text>
                    <Text style={styles.secondColumn}> </Text>
                    <Text style={styles.thirdColumn}>
                        {Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(baseImponible)}
                    </Text>
                </View>
                <View style={styles.row} >
                    <Text style={styles.fisrtColumn}>Comisión </Text>
                    <Text style={styles.secondColumn}>{comisionPorcetaje} %</Text>
                    <Text style={styles.thirdColumn}>
                        {Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(comision)}
                    </Text>
                </View>
                <View style={styles.row} >
                    <Text style={styles.fisrtColumn}>IRPF </Text>
                    <Text style={styles.secondColumn}> {irpfPorcetaje} % </Text>
                    <Text style={styles.thirdColumn}>
                        {Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(irpf)}
                    </Text>
                </View>
                <View style={styles.row} >
                    <Text style={styles.fisrtColumn}>IVA </Text>
                    <Text style={styles.secondColumn}> {ivaPorcetaje} %</Text>
                    <Text style={styles.thirdColumn}>
                        {Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(iva)}
                    </Text>
                </View>
                <View style={styles.row} >
                    <Text style={styles.fisrtColumn}>Importe </Text>
                    <Text style={styles.secondColumn}> </Text>
                    <Text style={styles.thirdColumn}>
                        {Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(importe)}
                    </Text>
                </View>
            </View>
        </Fragment>
    );
};

export default InvoiceSummary;