import React, { useContext, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'// context
import AuthProvider from "context/AuthProvider"
import TranslationProvider from 'context/TranslationProvider'
// styles
import { Tr, Td, Thead, Th, Tbody } from "styles/table"
import { AiFillCloseCircle } from "react-icons/ai"
import api from 'services/axios'

const TcCreditsTable = (props) => {

  const auth = useContext(AuthProvider)
  const { t } = useContext(TranslationProvider)
  const [modalText, setModaltext] = useState("")
  const [show, setShow] = useState(false)
  const [creditIdToDelete, setCreditIdToDelete] = useState(0)

  const handleClose = () => {
    setShow(false)
  }

  const deleteCredit = async () => {
    try {
      await api.post('/teleconnect_delete_credit_entry', {
        creditIdToDelete: creditIdToDelete,
      }, {
        headers: {
          'Authorization': `Bearer ${auth.accesToken}`
        }
      })
    } catch (err) {
    }
  }

  const showModalDelete = (e) => {
    setModaltext(t('deleteRechargeText1') + e.account.cuenta + t('deleteRechargeText2') + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(e.amount))
    setShow(true)
    setCreditIdToDelete(e.id)
  }

  const handleDelete = (e) => {
    setShow(false)
    deleteCredit()
    props.setRefresh(value => !value)
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body> {modalText} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button variant="primary" onClick={(e) => handleDelete(e)}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>{t('approvedBy')}</Th>
            <Th>{t('commercial_network.code')}</Th>
            <Th>{t('commercial_network.name')}</Th>
            <Th>{t('banks.date')}</Th>
            <Th>{t('banks.concept')}</Th>
            <Th>{t('days')}</Th>
            <Th>{t('banks.amount')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.rechargues.map((agent) => (
            <Tr key={agent.id}>
              <Td> {agent.user.name + ' ' + agent.user.surname} </Td>
              <Td> {agent.account.cuenta} </Td>
              {/* <Td> {agent.nombreContacto + ' ' + (agent.apellidosContacto!== undefined) ? agent.apellidosContacto : ""} </Td> */}
              <Td> {agent.account.nombreContacto + ' ' + agent.account.apellidosContacto} </Td>
              <Td> {(new Date(agent.created_at)).toLocaleString('es-Es')} </Td>
              <Td> {agent.reason} </Td>
              <Td type="number" > {agent.days} </Td>
              <Td type="number" >
                {(agent.amount !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(agent.amount) : 0}
              </Td>
              {agent.amount > 0 && (
                <Td>
                  <AiFillCloseCircle
                    onClick={(e) => showModalDelete(agent)}
                    style={{ "marginLeft": "10px", "color": "#007bff" }}
                  />
                </Td>
              )

              }
            </Tr>
          ))}
          <Tr type="splitter" />
          <Tr type="summary">
            {!auth.roles.includes("Reseller") && <Td>  </Td>}
            <Td> </Td>
            <Td> </Td>
            <Td> </Td>
            <Td> </Td>
            <Td> Total: </Td>
            <Td>
              {(props.totals.totalIngresos !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.totals.totalIngresos) : 0}
            </Td>
          </Tr>

        </Tbody>
      </Table>
    </div>
  )
}

export default TcCreditsTable