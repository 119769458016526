import React, { useContext, useState, forwardRef, useEffect } from "react"
import { AiFillFileExcel, AiFillFilePdf } from "react-icons/ai"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// components
import InvoiceData from 'components/teleconnect/resellers_invoices/jsonData/InvoiceData'
import PerPageFilter from 'components/controls/PerPageFilter'
import PdfDocument from 'components/teleconnect/resellers_invoices/Invoice'
//context
import AuthProvider from "context/AuthProvider"
// hooks
import useGetTcCommissions from 'hooks/useGetTcCommissions'
import useGetTcCompanies from 'hooks/useGetTcCompanies'
import useGetTcResellers from 'hooks/useGetTcResellers'
import useGetTcSubmanagers from 'hooks/useGetTcSubmanagers'
import exportToExcelCommissions from './exportToExcelCommissions'

const SearchBar = ({ t, resellerSelected, accesToken, companySelected, setCompanySelected, setResellerSelected, setCurrrentPage,
  setTextToFind, textToFind, monthSelected, setMonthSelected, perPage, setPerPage, submanagerSelected, setSubmanagerSelected}) => {

  const [downLoadPdfFile, setDownLoadPdfFile] = useState(0)
  const [downLoadExcelFile, setDownLoadExcelFile] = useState(0)

  const { companies } = useGetTcCompanies(accesToken)
  const { resellers } = useGetTcResellers(accesToken, companySelected, submanagerSelected)
  const { submanagers } = useGetTcSubmanagers(accesToken)

  const auth = useContext(AuthProvider)

  const { commissions, totals } = useGetTcCommissions(auth.accesToken, 1, 5000, companySelected, submanagerSelected, resellerSelected,
    null, monthSelected)

  useEffect(() => {
    exportToExcelCommissions(auth, commissions, downLoadExcelFile, setDownLoadExcelFile, resellerSelected, monthSelected, submanagerSelected)
  }, [auth, commissions, downLoadExcelFile, setDownLoadExcelFile, resellerSelected, monthSelected, submanagerSelected])

  let name = ""
  let submanagerName = (submanagerSelected.label === undefined) ? "" : submanagerSelected.label.slice(0, 22)
  let resellerName = (resellerSelected && resellerSelected.label !== undefined) ? resellerSelected.label.slice(0, 22) : ''
  
  if (auth.roles.includes("Reseller")) name = (auth.userName + " " + auth.userSurname).slice(0, 22)
  if (auth.roles.includes("Manager") || auth.roles.includes("root")) {
    if (submanagerSelected) name = submanagerName
    else name = resellerName
  }
  if (auth.roles.includes("Submanager") && resellerSelected) {
    if (resellerSelected ) name = resellerName 
    else name = submanagerName 
  }
  const fileName = name + " " + monthSelected.toISOString().slice(0, 7)


  let lastMonth = new Date()
  lastMonth.setMonth(lastMonth.getMonth() - 1)

  const handleCompanyChange = (e) => {
    setSubmanagerSelected(0)
    setResellerSelected(0)
    setCurrrentPage(1)
    setCompanySelected({
      label: e.label,
      value: e.value,
    })
  }

  const handleSubmanagerChange = (e) => {
    setCurrrentPage(1)
    setResellerSelected(0)
    setSubmanagerSelected({
      label: e.label,
      value: e.value,
    })
  }

  const handleResellerChange = (e) => {
    setCurrrentPage(1)
    setResellerSelected({
      label: e.label,
      value: e.value,
      iva: e.iva,
      irpf: e.irpf,
      commission: e.commission,
    })
  }

  const handleChangeInTextToFind = (e) => {
    setCurrrentPage(1)
    setTextToFind(e.target.value)
  }

  const clearPage = () => {
    setCompanySelected(0)
    setSubmanagerSelected(0)
    setResellerSelected(0)
    setTextToFind("")
    setMonthSelected(lastMonth)
    setDownLoadExcelFile(0)
    setDownLoadPdfFile(0)
    setCurrrentPage(1)
  }

  const manageExportToExcelCommissions = () => {
    setDownLoadExcelFile(1)
  }

  const manageDownloadPdfCommissions = () => {
    setDownLoadPdfFile(1)
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-secondary" onClick={onClick} ref={ref}>
      {value}
    </button>
  ))

  return (
    <div style={{ padding: "20px", marginTop: "10px" }}>
      <Container>
        <Row className="justify-content-md-center mb-3">
          <Col md="auto">
            <DatePicker
              selected={monthSelected}
              onChange={setMonthSelected}
              dateFormat="MM / yyyy"
              showMonthYearPicker
              customInput={<ExampleCustomInput />}
            />
          </Col>
          <Col md="auto">
            <Select
              options={companies}
              onChange={(e) => handleCompanyChange(e)}
              placeholder={t("graphs.company_to_search")}
              value={companySelected}
            />
          </Col>
          {!auth.roles.includes("Reseller") && !auth.roles.includes("Submanager") && (
            <Col md="auto">
              <Select
                options={submanagers}
                onChange={(e) => handleSubmanagerChange(e)}
                placeholder={t("graphs.submanager_to_search")}
                value={submanagerSelected}
              />
            </Col>)
          }
          {!auth.roles.includes("Reseller") && (
            <Col md="auto">
              <Select
                options={resellers}
                onChange={(e) => handleResellerChange(e)}
                placeholder={t("graphs.reseller_to_search")}
                value={resellerSelected}
              />
            </Col>)
          }
          <Col md="auto">
            <Form.Control
              onChange={(e) => handleChangeInTextToFind(e)}
              placeholder={t("commercial_network.search_in_any_field")}
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={textToFind}
            />
          </Col>
          <Col md="auto">
            <PerPageFilter
              setCurrrentPage={setCurrrentPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Col>
          <Col md="auto">
            <Button type="submit" variant="primary" onClick={clearPage}>
              {t("clean")}
            </Button>
          </Col>
          {((companySelected === 0 || companySelected === null)
            && ((resellerSelected !== 0) || auth.roles.includes("Submanager")  || auth.roles.includes("Reseller") || submanagerSelected !== 0)
          ) && (
              <>
                <Col md="auto" className="d-flex align-items-center">
                  <AiFillFileExcel
                    style={{ "color": "#007bff" }}
                    size={41}
                    onClick={manageExportToExcelCommissions}
                  />
                </Col>
                <Col md="auto" className="d-flex align-items-center">
                  <AiFillFilePdf
                    style={{ "color": "#007bff", marginLeft: -15 }}
                    size={41}
                    onClick={manageDownloadPdfCommissions}
                  />
                </Col>

              </>
            )
          }
          {downLoadPdfFile === 1 &&
            <div className="App">
              <PDFViewer width={800} height={800} showToolbar={false}>
                <PdfDocument
                  invoicedata={InvoiceData}
                  t={t}
                  reseller={resellerSelected}
                  name={name}
                  totals={totals}
                  monthSelected={monthSelected}
                  submanagerSelected={submanagerSelected}
                />
              </PDFViewer>

              <div className='download-link'>
                <PDFDownloadLink
                  document={
                    <PdfDocument
                      invoicedata={InvoiceData}
                      t={t}
                      reseller={resellerSelected}
                      name={name}
                      totals={totals}
                      monthSelected={monthSelected}
                      submanagerSelected={submanagerSelected}
                    />
                  }
                  fileName={fileName + ".pdf"}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading..." : "Descargar Factura"
                  }
                </PDFDownloadLink>
              </div>
            </div>
          }
        </Row>
      </Container>
    </div>
  )
}

export default SearchBar
