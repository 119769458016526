import React from 'react'
import { Page, Document, StyleSheet, Text } from "@react-pdf/renderer"
import InvoiceNo from "./InvoiceNo"
import InvoiceSummary from "./InvoiceSummary"

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
    },
    container: {
        paddingTop: 35,
        paddingBottom: 15,
        //backgroundColor: '#3778C2',
        flexDirection: 'row',
        alignItems: 'center',
        fontStyle: 'bold',
        borderBottomWidth: 2,
        borderBottomColor: '#3778C2',
    },
    leftOfRowFirst: {
        width: '25%',
    },
    middleOfRowFirst: {
        width: '40%',
    },
    rigthOfRowFirst: {
        width: '35%',
    },
    containerTwo: {
        paddingTop: 35,
        paddingBottom: 5,
        //backgroundColor: '#3778C2',
        flexDirection: 'row',
        alignItems: 'center',
        fontStyle: 'bold',
    },

    leftOfRowSecond: {
        width: '45%',
    },
    middleOfRowSecond: {
        width: '35%',
    },

})

const PdfDocument = ({ reseller, name, totals, monthSelected, submanagerSelected }) => {

    const date = (monthSelected) ? (monthSelected.getUTCMonth() + 1) + '/' + monthSelected.getUTCFullYear() : ''

    return (
        <Document>
            <Page size="A4" style={styles.page} >
                <div style={styles.containerTwo} >
                    <InvoiceNo
                        style={styles.leftOfRowSecond}
                        date={date}
                        name={name}
                    />
                    <Text style={styles.middleOfRowSecond}></Text>
                </div>
                <div>
                    <InvoiceSummary
                        reseller={reseller}
                        totals={totals}
                        submanagerSelected={submanagerSelected}
                    />
                </div>
            </Page>
        </Document>
    )
}

export default PdfDocument
