import React from "react"
import TcRenderSearchBar from './TcRenderSearchBar'
import { rechargesInitialStartDate, rechargesInitialEndDate } from "config/sinceUntilDatePicker"

const TcSearchBar = ({ searchParameters, setSearchParameters, setCurrrentPage, lookForText = true, showResellers = true,
  showPaginator = true, showActiveFilter = false, startDate = null, changeStartDate = null, endDate = null, changeEndDate = null,
  showSinceUntilDatePicker = false }) => {

  const handleCompanyChange = (e) => {
    setSearchParameters({
      ...searchParameters,
      company: {
        label: e.label, value: e.value,
      },
      manager: 0,
      submanager: 0,
      reseller: 0
    })
    if (showPaginator) setCurrrentPage(1)
  }

  const handleManagerChange = (e) => {
    setSearchParameters({
      ...searchParameters,
      manager: {
        label: e.label, value: e.value,
      },
      submanager: 0,
      reseller: 0
    })
    if (showPaginator) setCurrrentPage(1)
  }

  const handleSubmanagerChange = (e) => {
    setSearchParameters({
      ...searchParameters,
      submanager: {
        label: e.label, value: e.value,
      },
      reseller: 0
    })
    if (showPaginator) setCurrrentPage(1)
  }

  const handleResellerChange = (e) => {
    setSearchParameters({ ...searchParameters, reseller: { label: e.label, value: e.value, } })
    if (showPaginator) setCurrrentPage(1)
  }

  const clearPage = () => {
    setSearchParameters({
      ...searchParameters,
      company: 0,
      manager: 0,
      submanager: 0,
      reseller: 0,
      text: ""
    })
    if (showPaginator) setCurrrentPage(1)
    if (showSinceUntilDatePicker) changeStartDate({ startDate: rechargesInitialStartDate })
    if (showSinceUntilDatePicker) changeEndDate({ endDate: rechargesInitialEndDate })
  }

  return (
    <TcRenderSearchBar
      searchParameters={searchParameters}
      setSearchParameters={setSearchParameters}
      setCurrrentPage={setCurrrentPage}
      handleCompanyChange={handleCompanyChange}
      handleManagerChange={handleManagerChange}
      handleSubmanagerChange={handleSubmanagerChange}
      handleResellerChange={handleResellerChange}
      clearPage={clearPage}
      lookForText={lookForText}
      showResellers={showResellers}
      showPaginator={showPaginator}
      showActiveFilter={showActiveFilter}
      startDate={startDate}
      changeStartDate={changeStartDate}
      endDate={endDate}
      changeEndDate={changeEndDate}
      showSinceUntilDatePicker={showSinceUntilDatePicker}
    />
  )
}

export default TcSearchBar
