import React, { useEffect, useState, useContext } from 'react';
import Select from "react-select";

import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import getMovileurosAgents from '../../services/getMovileurosAgents';
import AuthProvider from '../../context/AuthProvider'
import TranslationProvider from '../../context/TranslationProvider'


const AgentsSearchBar = (props) => {

  const {t} = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const [movileurosAgents, setMovileurosAgents] = useState([])
  const [agentToFind, setAgentToFind] = useState([])

  const clearPage = () => {
    props.setTextToFind('')
    props.setAgentToFind('')
    props.setCurrrentPage(1)
    setAgentToFind(null)
  }

  const handleChange = (e) => {
    setAgentToFind({
      label: e.label,
      value: e.value
    })
    props.setAgentToFind(e.label)
  }

  useEffect(() => {
    getMovileurosAgents(auth.accesToken).then(movileurosAgents => setMovileurosAgents(movileurosAgents))
  }, [auth.accesToken])
  
  return (
    <div style={{ padding: "20px" }}>
      <Container>
        <Row className="justify-content-md-center mb-3">
          <Col md="auto">
            <Select
              options={movileurosAgents}
              onChange={(e) => handleChange(e)}
              placeholder={t('commercial_network.agent_to_search')}
              value={agentToFind}
            />
          </Col>
          <Col md="auto">
            <Form.Control
              onChange={(e) => props.setTextToFind(e.target.value)}
              placeholder={t('commercial_network.search_in_any_field')}
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={props.textToFind}
            />
          </Col>
          <Col md="auto">
            <Button
              type="submit"
              variant="primary"
              onClick={clearPage}
            >
              {t('clean')}
            </Button>
          </Col>

        </Row>
      </Container>
    </div>
  );
};

export default AgentsSearchBar;