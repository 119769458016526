import { URL } from './urls';

export default function getTcAgents(accesToken, currrentPage, perPage, company_id, reseller_id, textToFind, submanager_id = 0, manager_id = 0, onlyActive) {

  let companySelectedForApi = (company_id.value === undefined) ? 0 : company_id.value
  let submanagerSelectedForApi = (submanager_id.value === undefined) ? 0 : submanager_id.value
  let managerSelectedForApi = (manager_id.value === undefined) ? 0 : manager_id.value
  let resellerSelectedForApi = (reseller_id.value === undefined) ? 0 : reseller_id.value

  const apiURL = URL + `/teleconnect_accounts?page=${currrentPage}&per_page=${perPage}&company_id=${companySelectedForApi}&reseller_id=${resellerSelectedForApi}&textToFind=${textToFind}&submanager_id=${submanagerSelectedForApi}&manager_id=${managerSelectedForApi}&onlyActive=${onlyActive}`

  return fetch(apiURL, {
    headers: {
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response
    })
}