import React, { useContext, useEffect } from 'react'
import Select from "react-select"
import Container from 'react-bootstrap/Container'
import ReactAsyncSelect from "components/controls/ReactAsyncSelect"
import { bankConcepts } from 'services/bankConcepts'
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from 'context/TranslationProvider'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { FormLabel, FormButton2, FormError, FormHead } from "styles/form"

const CreateBankEntryForm = ({ amount, banks, errMsg, goBack, date, setDate, setBankId, setConceptId, movileurosAgents,
  setAgentId, setAmount, setComment, store }) => {

  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)

  const schema = yup.object({
    amount: yup.number().notOneOf([0]/* , 'my message' */).required(),
    date: yup.date().required(),
  }).required()

  const { reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    let defaultValues = {}
    defaultValues.date = date

    reset({ ...defaultValues })
  }, [reset, date])

  const onSubmit = () => store()

  // no dejo usar ciertos bancos para crear apuntes bancarios
  const banksFiltered = banks.filter(function (element) {
    return element.value !== 3 && element.value !== 6 && element.value !== 13;
  });
  
  return (
    <Container>
      <FormHead>{t('banks.create_bank_entry')}</FormHead>
      <form>
        <div>
          <FormLabel>{t('banks.bank')}</FormLabel>
          <Select
            options={banksFiltered}
            defaultValue={
              (auth.company.includes('Demo')) ? {
                label: "Demo Bank 1",
                value: 15
              } : {
                label: "Cajamar",
                value: 9
              }}
            onChange={(e) => setBankId(e.value)}
          />
        </div>
        <div>
          <FormLabel>{t('banks.date')}</FormLabel>
          <input
            {...register("date")}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type='text'
            className='form-control'
          />
          {errors.date && <FormError>{t('validation_errors.date')}</FormError>}
        </div>
        <div>
          <FormLabel>{t('banks.concept')}</FormLabel>
          <ReactAsyncSelect
            handleChange={(e) => setConceptId(e.value)}
            fetchData={bankConcepts}
            initialOption={{
              label: "INGRESO CLIENTE EFECTIVO",
              value: 1
            }}
          />

        </div>
        <div>
          <FormLabel>{t('commercial_network.agent')}</FormLabel>
          <Select
            options={movileurosAgents}
            onChange={(e) => setAgentId(e.value)}
          />
        </div>
        <div>
          <FormLabel>{t('banks.amount')}</FormLabel>
          <input
            {...register("amount")}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type='number'
            className='form-control'
          />
          {errors.amount && <FormError>{t('validation_errors.notZeroNumber')}</FormError>}
        </div>
        <div>
          <FormLabel>{t('banks.comment')}</FormLabel>
          <input
            onChange={(e) => setComment(e.target.value)}
            type='text'
            className='form-control'
          />
        </div>

        <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

        <FormButton2 onClick={handleSubmit(onSubmit)} >{t('store')}</FormButton2>
        <FormButton2 onClick={goBack} >{t('back')}</FormButton2>

      </form>
    </Container>
  )
}

export default CreateBankEntryForm