import Card from 'react-bootstrap/Card'; import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const LocationDetail = (props) => {

  const t = props.t;

  return (
    <Container>
      <Row className="justify-content-md-center mb-3">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('location.address')}</Card.Title>
              <Card.Text>{props.formParameters.address}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('location.city')}</Card.Title>
              <Card.Text>{props.formParameters.city}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('location.state')}</Card.Title>
              <Card.Text>{props.formParameters.state}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('location.zip_code')}</Card.Title>
              <Card.Text>{props.formParameters.zip_code}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
  );
};
export default LocationDetail;