const InvoiceData = {
    "id": "6377c4bbb4c8ed325985e023",
    "fullname": "Cliente de Pruebas",
    "invoice_no": "ME-2022/08-75",
    "balance": "$1,563.72",
    "company": "GEEKY",
    "email": "abbottshepherd@geeky.com",
    "phone": "+1 (860) 552-3510",
    "address": "362 Just Court, Navarre, Florida, 5211",
    "trans_date": "31-08-2022",
    "due_date": "30-01-2022",
    "items": [
        {
            "sno": 1,
            "desc": "minim mollit consectetur minim quis",
            "qty": 2,
            "rate": 1060.99
        },
        {
            "sno": 2,
            "desc": "irure voluptate veniam exercitation in",
            "qty": 9,
            "rate": 1100.11
        },
        {
            "sno": 3,
            "desc": "cillum dolor esse esse consequat",
            "qty": 9,
            "rate": 375.84
        },
        {
            "sno": 4,
            "desc": "deserunt amet do nulla adipisicing",
            "qty": 10,
            "rate": 423.47
        },
        {
            "sno": 5,
            "desc": "consectetur officia pariatur enim labore",
            "qty": 7,
            "rate": 434.77
        },
        {
            "sno": 6,
            "desc": "incididunt ullamco ullamco laboris magna",
            "qty": 10,
            "rate": 890.86
        },
        {
            "sno": 7,
            "desc": "eiusmod in eu ipsum nisi",
            "qty": 8,
            "rate": 774.24
        },
        {
            "sno": 8,
            "desc": "nostrud Lorem sit in cillum",
            "qty": 4,
            "rate": 238.62
        },
        {
            "sno": 9,
            "desc": "reprehenderit nulla dolore laborum elit",
            "qty": 5,
            "rate": 537.71
        },
        {
            "sno": 10,
            "desc": "minim dolore cillum sunt velit",
            "qty": 5,
            "rate": 723.9
        },
        {
            "sno": 11,
            "desc": "est do est cupidatat in",
            "qty": 7,
            "rate": 266.92
        },
        {
            "sno": 12,
            "desc": "pariatur veniam ad cillum ad",
            "qty": 6,
            "rate": 861.03
        },
    ]
}

export default InvoiceData;
