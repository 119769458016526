import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import api from 'services/axios'
import getBanks from 'services/getBanks'
import getMovileurosAgents from 'services/getMovileurosAgents'
import AuthProvider from 'context/AuthProvider'
import CreateBankEntryForm from './CreateBankEntryForm'

const CreateBankEntry = () => {

  const auth = useContext(AuthProvider)
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10))
  const [bankId, setBankId] = useState('')
  const [comment, setComment] = useState('')
  const [conceptId, setConceptId] = useState(null)
  const [agentId, setAgentId] = useState('')
  const [amount, setAmount] = useState(0)
  const navigate = useNavigate()
  const [banks, setBanks] = useState([])
  const [movileurosAgents, setMovileurosAgents] = useState([])

  const [errMsg, setErrMsg] = useState('')

  const store = async () => {
    try {
      await api.post('/bank_entries', {
        date: date,
        amount: amount,
        bankConceptId: conceptId ? conceptId : 1,
        bankId: bankId ? bankId : 
        (auth.company.includes('Demo')) ? 15 : 9,
        agentId: agentId,
        bankComment: comment,
      }, {
        headers: {
          'Authorization': `Bearer ${auth.accesToken}`
        }
      })
      navigate('/bank_entries')
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password')
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }
    }
  }

  const goBack = async (e) => {
    e.preventDefault()
    navigate('/bank_entries')
  }

  useEffect(() => {
    getBanks(auth.accesToken).then(banks => setBanks(banks))
  }, [auth.accesToken])

  useEffect(() => {
    getMovileurosAgents(auth.accesToken).then(movileurosAgents => setMovileurosAgents(movileurosAgents))
  }, [auth.accesToken])

  return (
    <CreateBankEntryForm
      amount={amount}
      banks={banks}
      errMsg={errMsg}
      goBack={goBack}
      date={date}
      setDate={setDate}
      setBankId={setBankId}
      setConceptId={setConceptId}
      movileurosAgents={movileurosAgents}
      setAgentId={setAgentId}
      setAmount={setAmount}
      setComment={setComment}
      store={store}
    />
  )
}

export default CreateBankEntry