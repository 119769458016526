import { URL } from './urls';

export default function getBanksbanksWithBalanceCheck(accesToken) {
  const apiURL = URL + `/banks_with_balance_check`

  return fetch(apiURL, {
    headers: {
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => res.json())
    .then(response => {
      return response
    })
}