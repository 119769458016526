import Card from 'react-bootstrap/Card'; import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SecurityDetail = (props) => {

  const t = props.t;

  return (
    <Container>
      <Row className="justify-content-md-center mb-3">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('user.isNeeded2FA')}</Card.Title>
              <Card.Text>{props.formParameters.isNeeded2FA}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('user.is_first_login')}</Card.Title>
              <Card.Text>{props.formParameters.is_first_login}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('user.notification_system')}</Card.Title>
              <Card.Text>{(props.formParameters.notification_system) ? props.formParameters.notification_system : 'Sin Definir'}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
  );
};
export default SecurityDetail;