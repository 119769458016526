import React from 'react';
import { Page, Document, StyleSheet, Image, Text, View } from "@react-pdf/renderer";
import logo from "../../logo.png";
// import InvoiceTitle from "./InvoiceTitle";
import InvoiceNo from "./InvoiceNo";
import BillTo from "./BillTo";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import InvoiceItemsTable from "./InvoiceItemsTable";
import CompanyData from "./CompanyData";

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
    },
    container: {
        paddingTop: 35,
        paddingBottom: 15,
        //backgroundColor: '#3778C2',
        flexDirection: 'row',
        alignItems: 'center',
        fontStyle: 'bold',
        borderBottomWidth: 2,
        borderBottomColor: '#3778C2',
    },
    leftOfRowFirst: {
        width: '25%',
    },
    middleOfRowFirst: {
        width: '40%',
    },
    rigthOfRowFirst: {
        width: '35%',
    },
    containerTwo: {
        paddingTop: 5,
        paddingBottom: 5,
        //backgroundColor: '#3778C2',
        flexDirection: 'row',
        alignItems: 'center',
        fontStyle: 'bold',
    },

    leftOfRowSecond: {
        width: '45%',
    },
    middleOfRowSecond: {
        width: '35%',
    },

});

const PdfDocument = (props) => {

    const invoicedata = props.invoicedata;
    const t = props.t;

    return (
        <Document>
            <Page size="A4" style={styles.page} >
                <div style={styles.container} >
                    <Image style={styles.leftOfRowFirst} src={logo} />
                    <Text style={styles.middleOfRowFirst}></Text>
                    <CompanyData style={styles.rigthOfRowFirst} invoice={invoicedata} />
                </div>

                <View >
                    <br />
                </View>

                <div style={styles.containerTwo} >
                    <InvoiceNo style={styles.leftOfRowSecond} invoice={invoicedata} t={t} />
                    <Text style={styles.middleOfRowSecond}></Text>
                    <BillTo invoice={invoicedata} t={t} />
                </div>

                <InvoiceItemsTable invoice={invoicedata} t={t} />
                <InvoiceThankYouMsg t={t} />
            </Page>
        </Document>
    );
}

export default PdfDocument;
