import Card from 'react-bootstrap/Card'
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const MainDetail = (props) => {

  const t = props.t

  return (
    <Container>
      <Row className="justify-content-md-left mb-3">
        <Col xs={2}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.company')}</Card.Title>
              <Card.Text>{props.formParameters.web}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={2}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.code')}</Card.Title>
              <Card.Text>{props.formParameters.cuenta}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={2}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.balance')}</Card.Title>
              <Card.Text>{props.formParameters.credito}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={2}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.credit')}</Card.Title>
              <Card.Text>{props.formParameters.creditoLimite}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-left mb-3">
        <Col xs={2}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.telefono1')}</Card.Title>
              <Card.Text>{props.formParameters.telefono1}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={2}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.telefono2')}</Card.Title>
              <Card.Text>{props.formParameters.telefono2}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.contacto')}</Card.Title>
              <Card.Text>{props.formParameters.contactNameAndSurname}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4}>
          <Card>
            <Card.Body>
              <Card.Title>{t('user.email')}</Card.Title>
              <Card.Text>{props.formParameters.email}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-left mb-3">
        <Col xs={2}>
          <Card>
            <Card.Body>
              <Card.Title>{t('user.created_at')}</Card.Title>
              <Card.Text>{props.formParameters.fechaAlta.slice(0, 10)}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={2}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.commission')}</Card.Title>
              <Card.Text>{props.formParameters.commission * 100 + ' %'}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.commercial')}</Card.Title>
              <Card.Text>{props.formParameters.distribuidor}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4}>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.tax_name')}</Card.Title>
              <Card.Text>{props.formParameters.empresa}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
export default MainDetail