import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"

//components
import Pagination from "components/controls/Pagination"
import TeleconnectCommissionsTable from "components/teleconnect/commissions/TeleconnectCommissionsTable"
import SearchBar from "components/teleconnect/commissions/SearchBar"
// hooks
import useGetTcCommissions from 'hooks/useGetTcCommissions'
//context - config
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"

const TeleconnectCommissions = () => {
  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const [companySelected, setCompanySelected] = useState(0)
  const [submanagerSelected, setSubmanagerSelected] = useState(0)
  const [resellerSelected, setResellerSelected] = useState(0)
  const [textToFind, setTextToFind] = useState('')

  const [currrentPage, setCurrrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  let lastMonth = new Date()
  lastMonth.setMonth(lastMonth.getMonth() - 1)
  const [monthSelected, setMonthSelected] = useState(lastMonth)

  const { commissions, lastPage, totals, error } = useGetTcCommissions(auth.accesToken, currrentPage, perPage, companySelected, submanagerSelected, resellerSelected,
    textToFind, monthSelected)

  if (error) {
    navigate('/')
    window.sessionStorage.setItem(
      'loggedNewIntranetAppUser', ''
    )
    window.location.reload()
  }
  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  return (
    <div style={{ padding: "20px" }}>
      <SearchBar
        t={t}
        accesToken={auth.accesToken}
        companySelected={companySelected}
        setCompanySelected={setCompanySelected}
        resellerSelected={resellerSelected}
        setResellerSelected={setResellerSelected}
        submanagerSelected={submanagerSelected}
        setSubmanagerSelected={setSubmanagerSelected}
        setCurrrentPage={setCurrrentPage}
        setTextToFind={setTextToFind}
        textToFind={textToFind}
        monthSelected={monthSelected}
        setMonthSelected={setMonthSelected}
        perPage={perPage}
        setPerPage={setPerPage}
        totals={totals}
      />
      <TeleconnectCommissionsTable
        commissions={commissions}
        totals={totals}
      />
      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
    </div>
  )
}

export default TeleconnectCommissions
