import { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"

//components
import SearchBar from 'components/banks/SearchBar'
import BanksTable from 'components/banks/BanksTable'
import Pagination from 'components/controls/Pagination'
import ShowBankCheckBar from 'components/banks/ShowBankCheckBar'
//context - config
import AuthProvider from 'context/AuthProvider'
import { banksInitialStartDate, banksInitialEndDate } from "config/sinceUntilDatePicker"
// hooks
import useSinceUntilDatePicker from 'hooks/useSinceUntilDatePicker'
// services
import deleteBankEntry from "services/deleteBankEntry"
import getBankEntries from 'services/getBankEntries'

const ShowBankEntries = () => {

  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const { startDate, endDate, changeStartDate, changeEndDate } = useSinceUntilDatePicker({
    initialStartDate: banksInitialStartDate, initialEndDate: banksInitialEndDate
  })

  const [bankEntries, setBankEntries] = useState([])
  const [bankSelected, setBankSelected] = useState(0)
  const [originSelected, setOriginSelected] = useState(0)

  const [currrentPage, setCurrrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const [errMsg, setErrMsg] = useState('')

  const [totals, setTotals] = useState([])
  const [textToFind, setTextToFind] = useState('')

  const [sortField, setSortField] = useState(null)
  const [sortOrder, setSortOrder] = useState(null)

  const [refreshPage, setRefresPage] = useState(false)

  /* eslint-disable */
  useEffect(() => {
    getBankEntries(auth.accesToken, currrentPage, perPage, bankSelected, startDate, endDate, textToFind, sortField, sortOrder, originSelected)
      .then(response => {
        setBankEntries(response.paginator.data)
        setLastPage(response.paginator.last_page)
        setPerPage(response.paginator.per_page)
        setTotals(response.totals)
        //console.log(response)
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
    setRefresPage(false); // Después de la recarga, establece el estado a falso nuevamente
  }, [currrentPage, perPage, bankSelected, startDate, endDate, textToFind, sortField, sortOrder, refreshPage, originSelected])
  /* eslint-disable */

  const sendDelete = async (e) => {
    deleteBankEntry(auth.accesToken, e)
      .then(response => {
        if (response.errorCode !== 0) {
          setErrMsg(response.message.message_en)
        }
      })
    setRefresPage((refreshPage) => !refreshPage)
  }

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  let showbalance = false
  if (currrentPage === 1 && bankSelected !== 0 && originSelected === 0 && (startDate > new Date(totals.initialBalanceDate))) showbalance = true

  return (
    <div style={{ padding: "20px" }}>
      <SearchBar
        textToFind={textToFind}
        setTextToFind={setTextToFind}
        setCurrrentPage={setCurrrentPage}
        bankSelected={bankSelected}
        setBankSelected={setBankSelected}
        startDate={startDate}
        changeStartDate={changeStartDate}
        endDate={endDate}
        changeEndDate={changeEndDate}
        perPage={perPage}
        setPerPage={setPerPage}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        originSelected={originSelected}
        setOriginSelected={setOriginSelected}
        setRefresPage={setRefresPage}
      />
      <BanksTable
        showbalance={showbalance}
        bankEntries={bankEntries}
        totals={totals}
        setCurrrentPage={setCurrrentPage}
        sortField={sortField}
        sortOrder={sortOrder}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        sendDelete={sendDelete}
      />
      <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>
      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
      {
        showbalance &&
        <ShowBankCheckBar
          bankSelected={bankSelected}
          setBankSelected={setBankSelected}
          auth={auth}
          balance={totals.balance}
        />
      }
    </div>
  )
}

export default ShowBankEntries