import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
// context
import AuthProvider from "context/AuthProvider"
import TranslationProvider from 'context/TranslationProvider'
// styles
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const TcRecharguesTable = (props) => {

  const auth = useContext(AuthProvider)
  const { t } = useContext(TranslationProvider)

  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr type="center">
            {!auth.roles.includes("Reseller") && <Th>{t('commercial_network.commercial')}</Th>}
            <Th>{t('commercial_network.code')}</Th>
            <Th>{t('commercial_network.name')}</Th>
            <Th>{t('banks.date')}</Th>
            <Th>{t('banks.concept')}</Th>
            <Th>{t('banks.method')}</Th>
            <Th>{t('banks.amount')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.rechargues.map((agent) => (
            <Tr key={agent.id}>
              {!auth.roles.includes("Reseller") && <Td> {agent.account.reseller.nombre} </Td>}
              <Td> {agent.account.cuenta} </Td>
              {/* <Td> {agent.nombreContacto + ' ' + (agent.apellidosContacto!== undefined) ? agent.apellidosContacto : ""} </Td> */}
              <Td> {agent.account.nombreContacto + ' ' + agent.account.apellidosContacto} </Td>
              <Td> {agent.date} </Td>
              <Td> {agent.reason} </Td>
              <Td> {agent.method} </Td>
              <Td type="number" >
                {(agent.amount !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(agent.amount) : 0}
              </Td>
            </Tr>
          ))}
          <Tr type="splitter" />
          <Tr type="summary">
            {!auth.roles.includes("Reseller") && <Td>  </Td>}
            <Td> </Td>
            <Td> </Td>
            <Td> </Td>
            <Td> </Td>
            <Td> Total: </Td>
            <Td>
              {(props.totals.totalIngresos !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.totals.totalIngresos) : 0}
            </Td>
          </Tr>

        </Tbody>
      </Table>
    </div>
  )
}

export default TcRecharguesTable