import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload, type, t, i18n } = this.props;

    if (type === 'daily') {
      if (i18n.language === 'es') {
        var tempDate = new Date(payload.value);
        var formattedDate = [tempDate.getDate(), tempDate.getMonth() + 1, tempDate.getFullYear()].join('/');
        payload.value = formattedDate;
      }
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666"  >
            {payload.value}
          </text>
        </g>
      );

    }
    if (type === 'weekly') return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {t('graphs.week') + ' ' + payload.value}
        </text>
      </g>
    );
    if (type === 'monthly') return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" >
          {t(payload.value)}
        </text>
      </g>
    );
  }
}

export default function Chart({ data, xlabel, t, i18n, type }) {
  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 35,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xlabel} tick={<CustomizedAxisTick type={type} t={t} i18n={i18n} />} />
        <YAxis yAxisId="left" orientation="left" stroke="#82ca9d" />
        <YAxis yAxisId="right" orientation="right" stroke="#8884d8" />
        <Tooltip />
        <Legend verticalAlign="top" height={30} />
        <Bar yAxisId="left" name={t('graphs.recharges')} dataKey="transactions" fill="#82ca9d" />
        <Bar yAxisId="right" name={t('graphs.sales')} dataKey="sales" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}
