import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"

//components
import Pagination from "components/controls/Pagination"
import TcSearchBar from "components/controls/SearchBar/TcSearchBar"
import TcAgentsTable from "components/teleconnect/agents/TcAgentsTable"
//context - config
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"
// services
import getTcAgents from "services/getTcAgents"

const TeleconnectAgents = () => {

  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const [searchParameters, setSearchParameters] = useState({
    company: 0,
    manager: 0,
    submanager: 0,
    reseller: 0,
    text: "",
    perPage: {
      value: 10,
      label: 10,
    },
    onlyActive: true,
  })
  const [teleconnectAgents, setTeleconnectAgents] = useState([])
  const [currrentPage, setCurrrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  /* eslint-disable */
  useEffect(() => {
    getTcAgents(
      auth.accesToken,
      currrentPage,
      searchParameters.perPage.value,
      searchParameters.company,
      searchParameters.reseller,
      searchParameters.text,
      searchParameters.submanager,
      searchParameters.manager,
      searchParameters.onlyActive,
    ).then((teleconnectAgents) => {
      setTeleconnectAgents(teleconnectAgents.data)
      setLastPage(teleconnectAgents.last_page)
    })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [auth.accesToken, currrentPage, searchParameters.perPage.value, searchParameters.company, searchParameters.submanager, searchParameters.reseller,
  searchParameters.text, searchParameters.manager, searchParameters.onlyActive])
  /* eslint-disable */

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  return (
    <div style={{ padding: "20px" }}>
      <TcSearchBar
        t={t}
        searchParameters={searchParameters}
        setSearchParameters={setSearchParameters}
        setCurrrentPage={setCurrrentPage}
        lookForText={true}
        showResellers={true}
        showPaginator={true}
        showActiveFilter={true}
      />
      <TcAgentsTable teleconnectAgents={teleconnectAgents} />
      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
    </div>
  )
}

export default TeleconnectAgents
