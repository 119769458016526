import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"

//components
import Pagination from "components/controls/Pagination"
import TcCreditsTable from "components/teleconnect/credits/TcCreditsTable"
import TcCreditSearchBar from "components/controls/SearchBar/TcCreditSearchBar"
//context - config
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"
import { rechargesInitialStartDate, rechargesInitialEndDate } from "config/sinceUntilDatePicker"
// hooks
import useSinceUntilDatePicker from 'hooks/useSinceUntilDatePicker'
// services
import getTcCredits from "services/getTcCredits"

const TeleconnectCredits = () => {

  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const [rechargues, setRechargues] = useState([])
  const [refresh, setRefresh] = useState([])
  const [currrentPage, setCurrrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [searchParameters, setSearchParameters] = useState({
    company: 0,
    manager: 0,
    submanager: 0,
    reseller: 0,
    text: "",
    perPage: {
      value: 10,
      label: 10,
    },
    onlyActive: true,
  })

  const [totals, setTotals] = useState([])

  const { startDate, endDate, changeStartDate, changeEndDate } = useSinceUntilDatePicker({
    initialStartDate: rechargesInitialStartDate, initialEndDate: rechargesInitialEndDate
  })

  useEffect(() => {
    /* eslint-disable */
    setTimeout(() => {
      getTcCredits(
        auth.accesToken,
        currrentPage,
        searchParameters.perPage.value,
        searchParameters.company,
        searchParameters.reseller,
        searchParameters.text,
        startDate.toISOString().slice(0, 10),
        endDate.toISOString().slice(0, 10),
        searchParameters.submanager,
        searchParameters.manager,
      ).then((rechargues) => {
        setRechargues(rechargues.paginator.data)
        setLastPage(rechargues.paginator.last_page)
        setTotals(rechargues.totals)
      })
        .catch(() => {
          navigate('/')
          window.sessionStorage.setItem(
            'loggedNewIntranetAppUser', ''
          )
          window.location.reload()
        })
    }, 1000)
  }, [auth.accesToken, currrentPage, searchParameters.perPage.value, searchParameters.text, startDate, endDate, refresh])

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  return (
    <div style={{ padding: "20px" }}>
      <TcCreditSearchBar
        t={t}
        searchParameters={searchParameters}
        setSearchParameters={setSearchParameters}
        setCurrrentPage={setCurrrentPage}
        lookForText={true}
        showPaginator={true}
        startDate={startDate}
        changeStartDate={changeStartDate}
        endDate={endDate}
        changeEndDate={changeEndDate}
        showSinceUntilDatePicker={true}
      />
      <TcCreditsTable
        rechargues={rechargues}
        totals={totals}
        setRefresh={setRefresh}
      />
      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
    </div>
  )
}

export default TeleconnectCredits
