//import { useContext } from 'react';
import { apiIntranet } from './axios';
//import AuthProvider from '../context/AuthProvider'


const SEND2FA_CODE_URL = '/send2FACode';
const ADD_IP_TO_WHITELIST_URL = '/addIpToWhiteList';


const Send2FACode = async (props) => {

  //const [errMsg, setErrMsg] = useState('');
  //const auth = useContext(AuthProvider)

  if (props.apiResponse.status != null) return;
  //if (!auth.isNeeded2FA) return;

  try {
    const response = await apiIntranet.post(SEND2FA_CODE_URL,
      JSON.stringify(props.twofaCode), {
      headers: {
        'Authorization': `Bearer ${props.loginParameters.accesToken}`
      }
    }
    );
    props.fetchApiResponse({
      status: response?.data?.errorCode ? 1 : 0,
      errorCode: response?.data?.errorCode,
      message: response?.data?.message
    })
    //return response;

    //console.log(JSON.stringify(response));
    //console.log(JSON.stringify(response));
    /* props.setLoginParameters({
      accesToken: response?.data?.accessToken
    }) */
  } catch (err) {
    /* if (!err?.response) {
      setErrMsg('No Server Response');
    } else if (err.response?.status === 400) {
      setErrMsg('Missing Username or Password');
    } else if (err.response?.status === 401) {
      setErrMsg('Unauthorized');
    } else {
      setErrMsg('Login Failed');
    } */
    props.fetchApiResponse({
      status: 2,
      //errorCode: response?.data?.errorCode
      errorCode: 2,
      message: err

    })
    //return err;
  }
}

const AddIpToWhiteListService = async (props) => {

  //const auth = useContext(AuthProvider)

  try {
    const response = await apiIntranet.post(ADD_IP_TO_WHITELIST_URL,
      JSON.stringify('mando esto para que funcione'), {
      headers: {
        'Authorization': `Bearer ${props.loginParameters.accesToken}`
      }
    }
    );
    props.fetchApiResponse({
      status: response?.data?.errorCode ? 1 : 0,
      errorCode: response?.data?.errorCode,
      message: response?.data?.message
    })
    //return response;

    //console.log(JSON.stringify(response));
    //console.log(JSON.stringify(response));
    /* props.setLoginParameters({
      accesToken: response?.data?.accessToken
    }) */
  } catch (err) {
    /* if (!err?.response) {
      setErrMsg('No Server Response');
    } else if (err.response?.status === 400) {
      setErrMsg('Missing Username or Password');
    } else if (err.response?.status === 401) {
      setErrMsg('Unauthorized');
    } else {
      setErrMsg('Login Failed');
    } */
    props.fetchApiResponse({
      status: 2,
      //errorCode: response?.data?.errorCode
      errorCode: 2,
      message: err

    })
    //return err;
  }
}

export { Send2FACode, AddIpToWhiteListService }
