import React, { useContext, useState } from 'react'
import changePassword from 'services/changePassword'
import AuthProvider from 'context/AuthProvider'
import RenderChangePassword from 'components/settings/changePassword/RenderChangePassword'

const Settings = () => {

  const auth = useContext(AuthProvider)
  const [pwd, setPwd] = useState('')
  const [newPwd, setNewPwd] = useState('')
  const [newPwdTwo, setNewPwdTwo] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    changePassword(auth.accesToken, pwd, newPwd)
      .then(response => {
        setPwd('')
        setNewPwd('')
        setNewPwdTwo('')
        if (response.status === "error") {
          setErrorMsg(response.message)
        }
        else setErrorMsg('password changed')
      })
  }

  return (
    <RenderChangePassword
      handleSubmit={handleSubmit}
      pwd={pwd}
      setPwd={setPwd}
      newPwd={newPwd}
      setNewPwd={setNewPwd}
      newPwdTwo={newPwdTwo}
      setNewPwdTwo={setNewPwdTwo}
      errorMsg={errorMsg}
    />
  )
}

export default Settings