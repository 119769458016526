import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import TranslationProvider from 'context/TranslationProvider'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { FormLabel, FormButton2, FormError, FormHead } from "styles/form"

const CreateCreditEntryForm = ({ amount, errMsg, goBack, days, setDays, setAmount, setComment, account, setAccount, store }) => {

  const { t } = useContext(TranslationProvider)

  const schema = yup.object({
    amount: yup.number().min(1).max(200).required(),
    days: yup.number().min(1).max(3).required(),
  }).required()

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })


  const onSubmit = () => store()

  return (
    <Container>
      <FormHead>{t('create_credit_entry')}</FormHead>
      <form>
        <div>
          <FormLabel>Cuenta</FormLabel>
          <input
            {...register("account")}
            value={account}
            onChange={(e) => setAccount(e.target.value)}
            type='text'
            className='form-control'
          />
        </div>

        <div>
          <FormLabel>{t('banks.amount')}</FormLabel>
          <input
            {...register("amount")}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type='number'
            className='form-control'
          />
          {errors.amount && <FormError>Introduzca entre 1 y 200 euros</FormError>}
        </div>
        <div>
          <FormLabel>{t('banks.comment')}</FormLabel>
          <input
            onChange={(e) => setComment(e.target.value)}
            type='text'
            className='form-control'
          />
        </div>
        <div>
          <FormLabel>{t('days')}</FormLabel>
          <input
            {...register("days")}
            value={days}
            onChange={(e) => setDays(e.target.value)}
            type='number'
            className='form-control'
          />
          {errors.days && <FormError>Introduzca entre 1 y 3 días</FormError>}
        </div>

        <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

        <FormButton2 onClick={handleSubmit(onSubmit)} >{t('store')}</FormButton2>
        <FormButton2 onClick={goBack} >{t('back')}</FormButton2>

      </form>
    </Container>
  )
}

export default CreateCreditEntryForm