import api from 'services/axios'
import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AuthProvider from 'context/AuthProvider'
import EditAgentForm from './EditAgentForm'
const EditAgent = () => {

  const auth = useContext(AuthProvider)
  const navigate = useNavigate()
  const { id } = useParams()
  const [formParameters, setFormParameters] = useState({
    sellerName: '',
    agentNameAndSurname: '',
    address: '',
    city: '',
    incomeCode: '',
    agentCredit: 0,
    balance: 0,
    phone: '',
    email: '',
  })
  const [errMsg, setErrMsg] = useState('')

  const update = async (e) => {
    //e.preventDefault()

    try {
      await api.put(`/agents_detail/${id}`, {
        agentCredit: parseFloat(formParameters.agentCredit),
        userPhone: formParameters.phone,
        userEmail: formParameters.email,
        address: formParameters.address,
        city: formParameters.city,
      }, {
        headers: {
          'Authorization': `Bearer ${auth.accesToken}`
        }
      })
      navigate('/commercial_network/agents')
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password')
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }
    }
  }

  /* eslint-disable */
  useEffect(() => {
    const getbankEntryById = async () => {
      const response = await api.get(`/react_agents/${id}`)
      setFormParameters({
        ...formParameters,
        agentNameAndSurname: response.data.registered_business_name,
        incomeCode: response.data.income_code,
        sellerName: response.data.seller[0].user.name,
        address: response.data.active_location_model.address1,
        city: response.data.active_location_model.city,
        agentCredit: parseFloat(response.data.credit).toFixed(2),
        balance: parseFloat(response.data.balance).toFixed(2),
        phone: response.data.user.phone,
        email: response.data.user.email,
      })
    }
    getbankEntryById()
    // hay una advertencia que en el vídeo quitan con la línea de abajo, aunque a mí no me la quita
    //-esLint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /* eslint-disable */

  const goBack = async (e) => {
    e.preventDefault()
    navigate('/commercial_network/agents')
  }

  return (
    <EditAgentForm
      formParameters={formParameters}
      setFormParameters={setFormParameters}
      goBack={goBack}
      update={update}
      errMsg={errMsg}
    />
  )
}
export default EditAgent