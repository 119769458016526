import React, { forwardRef } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const SinceUntilDatePicker = ({ setCurrrentPage, startDate, changeStartDate, endDate, changeEndDate }) => {

  let datePickerOneMaxDate = new Date()
  datePickerOneMaxDate.setDate(endDate.getDate() + 100)

  let datePickerTwoMaxDate = new Date()
  datePickerTwoMaxDate = new Date(startDate.getTime() - 86400000)

  let datePickerTwoMinDate = new Date()
  datePickerTwoMinDate.setDate(startDate.getDate() - 1000)

  const handleDatePicker1 = (e) => {
    changeStartDate({ startDate: e })
    setCurrrentPage(1)
  }

  const handleDatePicker2 = (e) => {
    changeEndDate({ endDate: e })
    setCurrrentPage(1)
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-secondary" onClick={onClick} ref={ref}>
      {value}
    </button>
  ))

  return (
    <>
      <Row className="justify-content-md-center mb-3">
        <Col md="auto">
          <DatePicker
            selected={startDate}
            onChange={(e) => handleDatePicker1(e)}
            dateFormat="dd / MM / yyyy"
            customInput={<ExampleCustomInput />}
            excludeDateIntervals={[{ start: endDate, end: datePickerOneMaxDate }]}
          />
        </Col>
        <Col md="auto">
          <DatePicker
            selected={endDate}
            onChange={(e) => handleDatePicker2(e)}
            dateFormat="dd / MM / yyyy"
            customInput={<ExampleCustomInput />}
            excludeDateIntervals={[
              { start: datePickerTwoMinDate, end: datePickerTwoMaxDate },
            ]}
          />
        </Col>
      </Row>
    </>
  )
}

export default SinceUntilDatePicker
