import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    CompanyName: {
        fontSize: 15,
        fontStyle: 'bold',
    },
    CompanyDataDetail: {
        fontSize: 12,
        fontStyle: 'bold',
    },
});

const CompanyData = ({ invoice }) => (
    <View>
        <Text style={styles.CompanyName}>Movileuros S.L.</Text>
        <Text style={styles.CompanyDataDetail}>Calle que ya se pondrá, nº 22</Text>
        <Text style={styles.CompanyDataDetail}>05200 Vigo (Pontevedra)</Text>
    </View >
);

export default CompanyData;