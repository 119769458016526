import React, { Fragment } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const InvoiceNo = ({ date, name }) => {

    const styles = StyleSheet.create({
        invoiceNoContainer: {
            justifyContent: 'flex-start',
            fontSize: 15,
        },
    })

    return (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                {(name !== "") && <Text >Comisiones de: {name}</Text>}
                <Text >{"Fecha: " + date} </Text>
                <Text >Factura Nº:</Text>
            </View >
        </Fragment>
    )
}

export default InvoiceNo