import { useRef, useState, useEffect } from 'react';
import api from 'services/axios';
//import '../../App.css';
import RenderLogin from './RenderLogin';

const LOGIN_URL = '/auth';

const Login = ({ setLoginParameters }) => {

  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post(LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const loginParameters = {
        isFirstLogin: response?.data?.user?.is_first_login,
        userName: response?.data?.user?.name,
        language: response?.data?.user?.language,
        company: response?.data?.user?.company.name,
        userSurname: response?.data?.user?.surname,
        companies: response?.data?.companies,
        roles: response?.data?.roles,
        accesToken: response?.data?.accessToken,
        isNeeded2FA: response?.data?.isNeeded2FA,
      }
      setLoginParameters(loginParameters)
      setUser('');
      setPwd('');
      window.sessionStorage.setItem(
        'loggedNewIntranetAppUser', JSON.stringify(loginParameters)
      )
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    }
  }

  return (
    <RenderLogin
      handleSubmit={handleSubmit}
      setUser={setUser}
      setPwd={setPwd}
      user={user}
      pwd={pwd}
      errRef={errRef}
      errMsg={errMsg}
    />

  )
}

export default Login
