import { useEffect, useState } from 'react'
import getTcManagers from 'services/getTcManagers'

export default function useGetTcManagers(accesToken) {

  const [managers, setManagers] = useState([])

  useEffect(() => {
    getTcManagers(accesToken).then(managers => setManagers(managers))
  }, [accesToken])

  return {
    managers
  }
} 