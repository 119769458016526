import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import { AiOutlineLogout } from "react-icons/ai"
import logo from "logo.svg"
//context
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"
// hooks
import { useNavigate } from "react-router-dom"

import RenderRoutes from "./RenderRoutes"

const RenderBrowserRouter = ({ changeLanguage }) => {
  const { i18n } = useContext(TranslationProvider)
  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const logout = (() => {
    navigate('/')
    window.sessionStorage.setItem(
      'loggedNewIntranetAppUser', ''
    )
    window.location.reload()
  })

  return (
    <div className="App">
      <Navbar
        bg="dark"
        variant="dark"
        sticky="top"
        expand="sm"
        collapseOnSelect
      >
        <Navbar.Brand as={Link} to="/">
          <img alt="logo" src={logo} width="40px" height="40px" />{" "}
          {t("pages.home")}
        </Navbar.Brand>

        <Navbar.Toggle className="coloring" />
        <Navbar.Collapse>
          <Nav className="me-auto" style={{ marginLeft: "20px" }}>
            {(auth.companies.find((element) => element.includes("Demo")) ||
              auth.companies.includes("MovilEuros")) && (
                <Nav.Link as={Link} to="/movileuros_sales_page">
                  {t("pages.sales")}
                </Nav.Link>
              )}

            {(auth.companies.find((element) => element.includes("Demo")) ||
              auth.companies.includes("MovilEuros")) && (
                <NavDropdown title={t("pages.commercial_network")}>
                  <NavDropdown.Item as={Link} to="/commercial_network/agents">
                    {t("pages.agents")}
                  </NavDropdown.Item>
                </NavDropdown>
              )}

            {(auth.companies.find((element) => element.includes("Demo")) ||
              auth.companies.includes("MovilEuros")) && (
                <NavDropdown title={t("pages.banks")}>
                  <NavDropdown.Item as={Link} to="/bank_entries">
                    {t("pages.bank_entries")}
                  </NavDropdown.Item>
                </NavDropdown>
              )}

            {auth.roles.includes("root") && (
              <Nav.Link as={Link} to="/invoices_page">
                {t("pages.invoices")}
              </Nav.Link>
            )}

            {auth.companies.includes("Teleconnect") && (
              <NavDropdown title="Teleconnect">
                <NavDropdown.Item as={Link} to="/teleconnect_agents_page">
                  {t("pages.agents")}
                </NavDropdown.Item>
                {!auth.roles.includes("Reseller") && (
                  <NavDropdown.Item as={Link} to="/teleconnect_resellers_page">
                    {t("pages.resellers")}
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/teleconnect_commissions_page">
                  {t("commissions.commissions")}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/teleconnect_rechargues_page">
                  {t("pages.rechargues")}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/teleconnect_credits_page">
                  {t("pages.credits")}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/teleconnect_sales_page">
                  {t("pages.sales")}
                </NavDropdown.Item>
              </NavDropdown>
            )}

            {auth.roles.includes("root") && (
              <NavDropdown title="Root">
                <NavDropdown.Item as={Link} to="/root_page">
                  Root
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/test_page">
                  Test
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>

          <Nav
            className="justify-content-end"
            style={{ marginRight: "80px" }}
          >
            <NavDropdown title={auth.userName}>
              <NavDropdown.Item as={Link} to="/change_password">
                {t("change_password.change_password_form")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/settings">
                {t("settings")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Button} onClick={logout}>
                {" "}
                <AiOutlineLogout style={{ marginRight: 5 }} /> {t("signOut")}{" "}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={i18n.language}>
              <NavDropdown.Item onClick={() => changeLanguage("es")}>
                Español
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeLanguage("en")}>
                English
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <RenderRoutes />
    </div>
  )
}

export default RenderBrowserRouter
