import React, { useContext } from 'react'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import PdfDocument from 'components/generateInvoice/Invoice'
import InvoiceData from 'components/generateInvoice/jsonData/InvoiceData'
import TranslationProvider from 'context/TranslationProvider'

const InvoicesPage = () => {

  const { t } = useContext(TranslationProvider)
  const fileName = "Invoice.pdf"

  return (
    <div>
      <PDFViewer width={800} height={800} showToolbar={false}>
        <PdfDocument invoicedata={InvoiceData} t={t} />
      </PDFViewer>

      <div className='download-link'>
        <PDFDownloadLink
          document={<PdfDocument invoicedata={InvoiceData} t={t} />}
          fileName={fileName}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading..." : "Download Invoice"
          }
        </PDFDownloadLink>
      </div>
    </div>
  )
}

export default InvoicesPage