import { useReducer } from "react";

const ACTIONS = {
  CHANGE_STARTDATE: "change_startDate",
  CHANGE_ENDDATE: "change_endDate",
};

const ACTIONS_REDUCERS = {
  [ACTIONS.CHANGE_STARTDATE]: (state, action) => ({
    ...state,
    startDate: action.payload,
  }),
  [ACTIONS.CHANGE_ENDDATE]: (state, action) => ({
    ...state,
    endDate: action.payload,
  }),
};

const reducer = (state, action) => {
  const actionReducer = ACTIONS_REDUCERS[action.type];
  return actionReducer ? actionReducer(state, action) : state;
};

export default function useSinceUntilDatePicker({ initialStartDate, initialEndDate } = {}) {
  const [{ startDate, endDate }, dispatch] = useReducer(reducer, {
    startDate: initialStartDate,
    endDate: initialEndDate
  });

  return {
    changeStartDate: ({ startDate }) =>
      dispatch({ type: ACTIONS.CHANGE_STARTDATE, payload: startDate }),
    changeEndDate: ({ endDate }) =>
      dispatch({ type: ACTIONS.CHANGE_ENDDATE, payload: endDate }),
    startDate,
    endDate
  };
}
