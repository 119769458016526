import { URL } from './urls';

  export default function checkBankBalanceService(accesToken, balance, bank_id) {
    let bankSelectedForApi = (bank_id.value === undefined) ? 0 : bank_id.value

  const apiURL = URL + `/check_bank_balance`
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accesToken}` },
    body: JSON.stringify({
      balance: `${balance}`,
      bank_id: `${bankSelectedForApi}`,
    })
  };

  return fetch(apiURL, requestOptions)
    .then(res => res.json())
    .then(response => {
      return response
    })
    .catch(() => 'Error en request'
    );

}