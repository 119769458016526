import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"

//components
import Pagination from "components/controls/Pagination"
import TcSearchBar from "components/controls/SearchBar/TcSearchBar"
import TcResellersTable from "components/teleconnect/resellers/TcResellersTable"
//context - config
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"
// services
import getTcResellersForTable from "services/getTcResellersForTable"

const TcResellers = () => {

  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const [searchParameters, setSearchParameters] = useState({
    company: 0,
    manager: 0,
    submanager: 0,
    reseller: 0,
    text: "",
    perPage: {
      value: 10,
      label: 10,
    },
  })

  const [resellers, setResellers] = useState([])

  const [currrentPage, setCurrrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  /* eslint-disable */
  useEffect(() => {
    getTcResellersForTable(
      auth.accesToken,
      currrentPage,
      searchParameters.perPage.value,
      searchParameters.company,
      searchParameters.text,
      searchParameters.submanager,
      searchParameters.manager,
    ).then((resellers) => {
      setResellers(resellers.data)
      setLastPage(resellers.last_page)
    })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [auth.accesToken, currrentPage, searchParameters.perPage.value, searchParameters.company, searchParameters.text, searchParameters.submanager, searchParameters.manager])
  /* eslint-disable */

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  return (
    <div style={{ padding: "20px" }}>
      <TcSearchBar
        t={t}
        searchParameters={searchParameters}
        setSearchParameters={setSearchParameters}
        setCurrrentPage={setCurrrentPage}
        lookForText={true}
        showResellers={false}
        showPaginator={true}
      />
      <TcResellersTable resellers={resellers} />
      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
    </div>
  )
}

export default TcResellers
