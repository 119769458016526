import React, { useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
//context
import TranslationProvider from "context/TranslationProvider"

// components
import PerPageFilter from './PerPageFilter'
import SinceUntilDatePicker from './SinceUntilDatePicker'

const TcRenderCreditSearchBar = ({ searchParameters, setSearchParameters, setCurrrentPage,clearPage,
  lookForText, showPaginator, showSinceUntilDatePicker,
  startDate, changeStartDate, endDate, changeEndDate }) => {

  const { t } = useContext(TranslationProvider)
  const navigate = useNavigate()

  return (
    <div style={{ margin: "10px 0px 30px 0px" }}>
      <Container>
        <Row className="justify-content-md-center">
          {showSinceUntilDatePicker &&
            <Col md="auto">
              <SinceUntilDatePicker
                setCurrrentPage={setCurrrentPage}
                startDate={startDate}
                changeStartDate={changeStartDate}
                endDate={endDate}
                changeEndDate={changeEndDate}
              />
            </Col>
          }
          {lookForText &&
            <Col md="auto">
              <Form.Control
                onChange={(e) => setSearchParameters({ ...searchParameters, text: e.target.value })}
                placeholder={t("commercial_network.search_in_any_field")}
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchParameters.text}
              />
            </Col>
          }
          {showPaginator &&
            <Col md="auto">
              <PerPageFilter
                setCurrrentPage={setCurrrentPage}
                searchParameters={searchParameters}
                setSearchParameters={setSearchParameters}
              />
            </Col>
          }
          <Col md="auto">
            <Button type="submit" variant="primary" onClick={clearPage}>
              {t("clean")}
            </Button>
          </Col>
          <Col md="auto">
            <Button
              variant="primary"
              onClick={() => { navigate('/create_credit_entry') }}>
              {t('create_credit_entry')}
            </Button>
          </Col>

        </Row>
      </Container>
    </div>
  )
}

export default TcRenderCreditSearchBar
