import {URL} from './urls';

export default function getTcSubmanagers(accesToken, manager_id = 0) {

  let managerSelectedForApi = (manager_id.value === undefined) ? 0 : manager_id.value

  const apiURL = URL + `/teleconnect_submanagers?manager_id=${managerSelectedForApi}`

  return fetch(apiURL, {
    headers: {
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => res.json())
    .then(response => {
      const tempArray = [];
      if (response) {
        if (response.length) {
          response.forEach((element) => {
            tempArray.push({
              label: `${element.user?.name} ${element.user?.surname}`,
              value: element.id,
            });
          });
        } else {
          tempArray.push({
            label: `${response.user?.name} ${response.user?.surname}`,
            value: response.id,
          });
        }
      }
      return tempArray
    })
}