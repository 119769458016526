import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import { AiFillEdit, AiFillCloseCircle } from "react-icons/ai"
import TranslationProvider from 'context/TranslationProvider'
import SortTable from "components/controls/SortTable"
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const BanksTable = (props) => {

  const { t } = useContext(TranslationProvider)

  const [show, setShow] = useState(false)
  const [modalText, setModaltext] = useState("")
  const [idToDelete, setIdToDelete] = useState(0)

  const handleClose = () => setShow(false)
  const showModalDelete = (e) => {
    setModaltext(t('banks.deletetext1') + e.bank.name + t('banks.deletetext2') + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(e.amount))
    setShow(true)
    setIdToDelete(e.id)
  }

  const handleDelete = (e) => {
    setShow(false)
    props.sendDelete(idToDelete)
  }

  let balance = props.totals.balance

  const getRowStyle = (bankEntry) => {
    if (bankEntry.comment !== null && bankEntry.comment.includes("comprobar")) {
      return { backgroundColor: "#f598ad" };
    } else if (bankEntry.amount <= 0) {
      return { backgroundColor: "#fad796" };
    } else {
      return {}; // Si no hay estilo específico, devuelve un objeto vacío
    }
  };


  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body> {modalText} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button variant="primary" onClick={(e) => handleDelete(e)}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>{t('banks.bank')}</Th>
            <Th>{t('banks.date')}
              <SortTable
                field={"date"}
                sortField={props.sortField}
                sortOrder={props.sortOrder}
                setSortField={props.setSortField}
                setSortOrder={props.setSortOrder}
                setCurrrentPage={props.setCurrrentPage}
              />
            </Th>
            <Th>{t('banks.hour')}</Th>
            <Th>Origen</Th>
            <Th>{t('banks.code')}</Th>
            <Th>{t('banks.concept')}</Th>
            <Th>{t('banks.comment')}</Th>
            <Th>{t('banks.amount')}
              <SortTable
                field={"amount"}
                sortField={props.sortField}
                sortOrder={props.sortOrder}
                setSortField={props.setSortField}
                setSortOrder={props.setSortOrder}
                setCurrrentPage={props.setCurrrentPage}
              />
            </Th>
            {props.showbalance && <Th>{t('banks.balance')}</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {props.bankEntries.map((bankEntry) => (
            <Tr>
              <Td style={getRowStyle(bankEntry)}> {bankEntry.bank.name} </Td>
              <Td style={getRowStyle(bankEntry)} type="center"> {bankEntry.date} </Td>
              <Td style={getRowStyle(bankEntry)} type="center"> {(new Date(bankEntry.created_at)).toLocaleTimeString('es-Es')} </Td>
              <Td style={getRowStyle(bankEntry)} type="center"> {bankEntry.cash_origin?.name} </Td>
              <Td style={getRowStyle(bankEntry)}> {bankEntry.agent
                ? bankEntry.agent.income_code
                : (bankEntry.account
                  ? bankEntry.account.cuenta
                  : (bankEntry.locutorio_prosper) ? bankEntry.locutorio_prosper.username : '')
              }
              </Td>
              <Td style={getRowStyle(bankEntry)}> {bankEntry.concept ? bankEntry.concept.name : ''} </Td>
              <Td style={getRowStyle(bankEntry)}> {bankEntry.comment} </Td>
              <Td style={getRowStyle(bankEntry)} type="number" >
                {(bankEntry.amount !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(bankEntry.amount) : 0}
              </Td>
              {props.showbalance && <>
                <Td style={getRowStyle(bankEntry)} type="number">
                  {Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(balance)}
                </Td>
                <Td style={{ "display": "none" }}>
                  {balance -= 1 * bankEntry.amount}
                </Td>
              </>
              }
              <Td>
                <Link to={`/bank_entries/edit/${bankEntry.id}`}>
                  <AiFillEdit />
                </Link>
                {[2, 4, 5, 7, 8, 9, 10, 11, 17].includes(bankEntry.bank.id) &&
                  <AiFillCloseCircle
                    onClick={(e) => showModalDelete(bankEntry)}
                    style={{ "marginLeft": "10px", "color": "#007bff" }}
                  />}
              </Td>
            </Tr>
          ))}
          <Tr type="splitter" />
          <Tr type="summary">
            <Td> </Td>
            <Td> </Td>
            <Td> </Td>
            <Td> </Td>
            <Td> </Td>
            <Td> </Td>
            <Td> Total: </Td>
            <Td>
              {(props.totals.totalIngresos !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.totals.totalIngresos) : 0}
            </Td>
          </Tr>
        </Tbody>
      </Table>

    </div>
  )
}

export default BanksTable