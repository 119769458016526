import * as XLSX from "xlsx"

export default function exportToExcelCommissions(auth, commissions, downLoadExcelFile, setDownLoadExcelFile, resellerSelected,
  monthSelected, submanagerSelected) {

  const excelData = commissions.map(agent => ({
    "Nombre": agent.nombreContacto + ' ' + agent.apellidosContacto,
    "Código": agent.cuenta,
    "Distribuidor": agent.distribuidor,
    "Llamadas": parseFloat(agent.calls),
    "Minutos": parseFloat(agent.minutos),
    "Ventas": parseFloat(agent.ventas),
    "Ingresos": parseFloat(agent.ingresos),
  }))

  //console.log("exportToExcelCommissions is running")
  if (excelData[0] === undefined) return {}
  //console.log("exportToExcelCommissions superado return 1")
  if (resellerSelected) if (excelData[0]["Distribuidor"] === undefined) return {}
  //console.log("exportToExcelCommissions superado return 2")

  let submanagerName = (submanagerSelected.label === undefined) ? "" : submanagerSelected.label.slice(0, 22)

  let name = ""
  if (auth.roles.includes("Reseller")) name = (auth.userName + " " + auth.userSurname).slice(0, 22)
  if (auth.roles.includes("Manager") || auth.roles.includes("root")) {
    if (submanagerSelected) name = submanagerName
    else name = excelData[0]["Distribuidor"].slice(0, 22)
  }
  if (auth.roles.includes("Submanager")) {
    if (resellerSelected) name = excelData[0]["Distribuidor"].slice(0, 22)
    else name = name = (auth.userName + " " + auth.userSurname).slice(0, 22)
  }
  let data = (resellerSelected) || auth.roles.includes("Reseller") ? excelData.filter(element => delete element.Distribuidor) : excelData

  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new();

  //worksheet["!cols"] = [ { wch: 15 } ]; // set column A width to 10 characters
  const max_width = data.reduce((w, r) => Math.max(w, r.Nombre.length), 15);
  worksheet["!cols"] = [{ wch: max_width + 2 }];

  const fileName = name + " " + monthSelected.toISOString().slice(0, 7)

  XLSX.utils.book_append_sheet(workbook, worksheet, fileName)

  if (data && data.length && downLoadExcelFile) {
    XLSX.writeFile(workbook, fileName + ".xlsx", { compression: true });
    setDownLoadExcelFile(0)
  }

  return {}
}
