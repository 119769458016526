import Card from 'react-bootstrap/Card'; import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const MainDetail = (props) => {

  const t = props.t;

  return (
    <Container>
      <Row className="justify-content-md-center mb-3">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.commercial')}</Card.Title>
              <Card.Text>{props.formParameters.sellerName}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.code')}</Card.Title>
              <Card.Text>{props.formParameters.incomeCode}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.credit')}</Card.Title>
              <Card.Text>{props.formParameters.agentCredit}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('commercial_network.balance')}</Card.Title>
              <Card.Text>{props.formParameters.balance}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('user.created_at')}</Card.Title>
              <Card.Text>{props.formParameters.created_at.substring(0, 10)}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('user.updated_at')}</Card.Title>
              <Card.Text>{props.formParameters.updated_at.substring(0, 10)}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('user.commissions_retrieve_method')}</Card.Title>
              <Card.Text>{props.formParameters.commissions_retrieve_method}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t('user.printer')}</Card.Title>
              <Card.Text>{props.formParameters.printer}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default MainDetail;