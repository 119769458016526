import { useRef, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
//import logo from 'logo_prosper.png'
import logo from 'logo.svg'
import { Form, FormInput, FormButton, TextMuted } from "styles/form"

const RenderLogin = (props) => {

  const userRef = useRef()

  useEffect(() => {
    userRef.current.focus()
  }, [])

  return (
    <div>
      <Form onSubmit={props.handleSubmit}>
        <img className="App-logo"
          src={logo}
          alt="React Logo"
        />
        <h1 className="mb-3 fs-3 fw-normal">Please sign in</h1>

        <FormInput
          type="email" size="lg"
          placeholder="Email address"
          autoComplete="off"
          onChange={(e) => props.setUser(e.target.value)}
          value={props.user}
          required
          ref={userRef}
        />

        <FormInput
          type="password"
          size="lg"
          placeholder="Password"
          autoComplete="off"
          onChange={(e) => props.setPwd(e.target.value)}
          value={props.pwd}
          required
        />

        {props.errMsg &&
          <Alert
            variant="danger"
          >
            {props.errMsg}
          </Alert>
        }
        <FormButton>Sign in</FormButton>
        <TextMuted>&copy; Blopadev 2022</TextMuted>
      </Form>
    </div>
  )
}

export default RenderLogin
