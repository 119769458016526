import { URL } from './urls';

export default function getMovileurosSales(accesToken) {
  const apiURL = URL + `/movileuros_graphs`

  return fetch(apiURL, {
    headers: {
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => res.json())
    .then(response => {
      return response
    })
}