import React from 'react'

const ActiveFilter = ({ searchParameters, setSearchParameters, setCurrrentPage }) => {

  const handleChange = (e) => {
    setSearchParameters({
      ...searchParameters,
      onlyActive: e.target.checked
    })
    setCurrrentPage(1)
  }

  return (
    <>Sólo Activos
      <input style={{ marginLeft: "10px", marginTop: "12px" }}
        value={searchParameters.onlyActive}
        checked={searchParameters.onlyActive === true}
        onChange={(e) => handleChange(e)}
        type='checkbox'
      />
    </>
  );
};

export default ActiveFilter