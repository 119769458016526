import React from 'react'

const TestPage = () => {

  return (
    <section className="App-content">
      Esta página es de Pruebas
    </section>
  )
}

export default TestPage