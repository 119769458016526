import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
// context
import AuthProvider from "context/AuthProvider"
import TranslationProvider from 'context/TranslationProvider'
// styles
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const TeleconnectCommissionsTable = (props) => {

  const { t } = useContext(TranslationProvider)
  let minutes = 0
  const auth = useContext(AuthProvider)

  props.commissions.map((agent) => (
    minutes = minutes + parseFloat(agent.minutos)
  ))

  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr>
            {!auth.roles.includes("Reseller") && <Th>{t('commercial_network.commercial')}</Th>}
            <Th>{t('commercial_network.code')}</Th>
            <Th>{t('commercial_network.name')}</Th>
            <Th>{t('commissions.calls')}</Th>
            <Th>{t('commissions.minutes')}</Th>
            <Th>{t('commissions.sales')}</Th>
            <Th>{t('commissions.incomes')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.commissions.map((agent) => (
            <Tr key={agent.cuenta}>
              {!auth.roles.includes("Reseller") && <Td> {agent.distribuidor} </Td>}
              <Td> {agent.cuenta} </Td>
              {/* <Td> {agent.nombreContacto + ' ' + (agent.apellidosContacto!== undefined) ? agent.apellidosContacto : ""} </Td> */}
              <Td> {agent.nombreContacto + ' ' + agent.apellidosContacto} </Td>
              <Td type="number">
                {(agent.calls !== undefined) ? Intl.NumberFormat('de-DE').format(agent.calls) : 0}
              </Td>
              <Td type="number">
                {(agent.minutos !== undefined) ? Intl.NumberFormat('de-DE').format(agent.minutos) : 0}
              </Td>
              <Td type="number">
                {(agent.ventas !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(agent.ventas) : 0}
              </Td>
              <Td type="number">
                {(agent.ingresos !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(agent.ingresos) : 0}
              </Td>
            </Tr>
          ))}
          <Tr type="splitter" />
          <Tr type="summary">
            {!auth.roles.includes("Reseller") && <Td>  </Td>}
            <Td> </Td>
            <Td> Totales: </Td>
            <Td>
              {(props.totals.totalCalls !== undefined) ? Intl.NumberFormat('de-DE').format(props.totals.totalCalls) : 0}
            </Td>
            <Td>
              {(props.totals.totalMinutos !== undefined) ? Intl.NumberFormat('de-DE').format(props.totals.totalMinutos) : 0}
            </Td>
            <Td>
              {(props.totals.totalVentas !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.totals.totalVentas) : 0}
            </Td>
            <Td>
              {(props.totals.totalIngresos !== undefined) ? Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.totals.totalIngresos) : 0}
            </Td>
          </Tr>

        </Tbody>
      </Table>
    </div>
  )
}

export default TeleconnectCommissionsTable