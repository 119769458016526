import { useEffect, useState } from 'react'
import getTcSubmanagers from 'services/getTcSubmanagers'

export default function useGetTcSubmanagers(accesToken, manager) {

  const [submanagers, setSubmanagers] = useState([])

  useEffect(() => {
    getTcSubmanagers(accesToken, manager).then(submanagers => setSubmanagers(submanagers))
  }, [accesToken, manager])

  return {
    submanagers
  }
} 