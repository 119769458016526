import api from '../../services/axios';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MainDetail from './agent_detail/MainDetail';
import LocationDetail from './agent_detail/LocationDetail';
import SecurityDetail from './agent_detail/SecurityDetail';
import TranslationProvider from '../../context/TranslationProvider'

const ShowAgent = (props) => {

  const { t } = useContext(TranslationProvider)
  const navigate = useNavigate()
  const { id } = useParams()
  const [formParameters, setFormParameters] = useState({
    sellerName: '',
    agentNameAndSurname: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    incomeCode: '',
    created_at: '',
    updated_at: '',
    acountType: '',
    commissions_retrieve_method: '',
    printer: '',
    agentCredit: 0,
    isNeeded2FA: 0,
    is_first_login: 0,
    notification_system: '',
    balance: 0
  })
  const goBack = async (e) => {
    e.preventDefault();
    navigate('/commercial_network/agents')
  }

  /* eslint-disable */
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    const getbankEntryById = async () => {
      const response = await api.get(`/react_agents/${id}`)
      setFormParameters({
        ...formParameters,
        agentNameAndSurname: response.data.registered_business_name,
        incomeCode: response.data.income_code,
        sellerName: response.data.seller[0].user.name,
        phone: response.data.user.phone,
        email: response.data.user.email,
        isNeeded2FA: response.data.user.isNeeded2FA,
        is_first_login: response.data.user.is_first_login,
        notification_system: response.data.user.notification_system,
        created_at: response.data.created_at,
        updated_at: response.data.updated_at,
        acountType: response.data.acountType,
        commissions_retrieve_method: response.data.commissions_retrieve_method,
        printer: response.data.printer,
        address: response.data.active_location_model.address1,
        city: response.data.active_location_model.city,
        state: response.data.active_location_model.state,
        zip_code: response.data.active_location_model.zip_code,
        agentCredit: parseFloat(response.data.credit).toFixed(2),
        balance: parseFloat(response.data.balance).toFixed(2),
      })
    }
    getbankEntryById()
  }, [])
  /* eslint-disable */

  return (
    <div style={{ padding: "20px" }}>
      <h3 style={{ marginBottom: "20px", textAlign: "center" }}>{t('commercial_network.agent_detail')}</h3>
      <Tabs
        defaultActiveKey="summary"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="summary" title={t('summary')}>
          <MainDetail
            formParameters={formParameters}
            t={t}
          />
        </Tab>
        <Tab eventKey="address" title={t('location.address')}>
          <LocationDetail
            formParameters={formParameters}
            t={t}
          />
        </Tab>
        <Tab eventKey="security" title={t('security')}>
          <SecurityDetail
            formParameters={formParameters}
            t={t}
          />
        </Tab>
      </Tabs>

      <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

      <Button style={{ margin: "10px", padding: "10px" }} onClick={goBack} className='btn btn-primary'>{t('back')}</Button>

    </div>
  );
};
export default ShowAgent;