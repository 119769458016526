import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import api from 'services/axios'
import AuthProvider from 'context/AuthProvider'
import CreateCreditEntryForm from './CreateCreditEntryForm'

const CreateCreditEntry = () => {

  const auth = useContext(AuthProvider)
  const [comment, setComment] = useState('')
  const [account, setAccount] = useState('')
  const [amount, setAmount] = useState(5)
  const [days, setDays] = useState(1)
  const navigate = useNavigate()

  const [errMsg, setErrMsg] = useState('')

  const store = async () => {
    try {
      const response = await api.post('/teleconnect_add_credit_entry', {
        amount: amount,
        account: account,
        comment: comment,
        days: days,
      }, {
        headers: {
          'Authorization': `Bearer ${auth.accesToken}`
        }
      })
      if (response?.data?.error !== undefined ) setErrMsg(response.data.error)
      else navigate('/teleconnect_credits_page')
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password')
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }
    }
  }

  const goBack = async (e) => {
    e.preventDefault()
    navigate('/teleconnect_credits_page')
  }

  return (
    <CreateCreditEntryForm
      amount={amount}
      errMsg={errMsg}
      goBack={goBack}
      days={days}
      setDays={setDays}
      setAmount={setAmount}
      setComment={setComment}
      account={account}
      setAccount={setAccount}
      store={store}
    />
  )
}

export default CreateCreditEntry