import React, { useEffect, useContext } from 'react'
// react-bootstrap
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
// context
import TranslationProvider from 'context/TranslationProvider'
// styles
import { FormLabel, FormButton2, FormError, FormHead } from "styles/form"
// validation
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

const EditResellerForm = ({ formParameters, setFormParameters, update, modalText, setModaltext,
  handleClose, goBack, errMsg, show }) => {

  const { t } = useContext(TranslationProvider)

  const schema = yup.object({
    iva: yup.number().oneOf([0, 21]).required(),
    irpf: yup.number().oneOf([0, 15]).required(),
    email: yup.string().email(),
    password: yup.string()
      .test("password", function (value) {
        return (!formParameters.hasAnIntranetUser && this.parent.email === "" && value === "")
          || (!formParameters.hasAnIntranetUser && this.parent.email !== "" && value !== "")
          || (formParameters.hasAnIntranetUser && this.parent.email !== "")
      })
      .test(
        "len",
        "can be empty or with string at least 2 characters and not more than 10",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 5 && val.length <= 50);
        }
      )
  }).required()

  const { reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    let defaultValues = {}
    defaultValues.iva = formParameters.iva * 100
    defaultValues.irpf = formParameters.irpf * 100
    defaultValues.email = formParameters.email
    defaultValues.password = formParameters.password
    reset({ ...defaultValues })
  }, [reset, formParameters.iva, formParameters.irpf, formParameters.email, formParameters.password])


  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body style={{ textAlign: "center", padding: "30px" }}> {modalText} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <FormHead>{t('commercial_network.edit_reseller')}</FormHead>
      <form>
        <div>
          <FormLabel>{t('commercial_network.name')}</FormLabel>
          <input
            value={formParameters.nombre}
            type='text'
            className='form-control'
            disabled
          />
        </div>
        <div>
          <FormLabel>{t('commercial_network.code')}</FormLabel>
          <input
            value={formParameters.codigo}
            type='text'
            className='form-control'
            disabled
          />
        </div>
        <div>
          <FormLabel>{t('user.email')}</FormLabel>
          <input
            {...register("email")}
            value={formParameters.email}
            type='text'
            className='form-control'
            placeholder="Introduce un email para que el Reseller acceda a Intranet"
            onChange={(e) => setFormParameters({
              ...formParameters,
              email: e.target.value
            })}
          />
          {errors.email && <FormError>{t('validation_errors.email')}</FormError>}
        </div>
        <div>
          <FormLabel>Password</FormLabel>
          <input
            value={formParameters.password}
            type='password'
            className='form-control'
            onChange={(e) => setFormParameters({
              ...formParameters,
              password: e.target.value
            })}
          />
          {errors.password && <FormError>{t('validation_errors.required')}</FormError>}
        </div>
        <div>
          <FormLabel>{t('user.phone')}</FormLabel>
          <input
            value={formParameters.telefono}
            type='text'
            className='form-control'
            disabled
          />
        </div>
        <div>
          <FormLabel>IVA</FormLabel>
          <input
            {...register("iva")}
            value={formParameters.iva * 100}
            onChange={(e) => setFormParameters({
              ...formParameters,
              iva: e.target.value / 100
            })}
            type='number'
            className='form-control'
          />
          {errors.iva && <FormError>{t('validation_errors.iva')}</FormError>}
        </div>
        <div>
          <FormLabel>IRPF</FormLabel>
          <input
            {...register("irpf")}
            value={formParameters.irpf * 100}
            onChange={(e) => setFormParameters({
              ...formParameters,
              irpf: e.target.value / 100
            })}
            type='number'
            className='form-control'
          />
          {errors.irpf && <FormError>{t('validation_errors.irpf')}</FormError>}
        </div>
        <div>
          <FormLabel>Doble factor
            <input style={{ marginLeft: "10px" }}
              value={formParameters.isNeeded2FA}
              checked={formParameters.isNeeded2FA === true}
              onChange={(e) => setFormParameters({
                ...formParameters,
                isNeeded2FA: e.target.checked
              })}
              type='checkbox'
            />
          </FormLabel>
        </div>
        <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

        <FormButton2 onClick={handleSubmit(update)}>{t('update')}</FormButton2>
        <FormButton2 onClick={goBack}>{t('back')}</FormButton2>

      </form>
    </Container>
  )
}
export default EditResellerForm